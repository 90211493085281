import { BaseApi } from "../base";
import * as LOGIN_PATH from "../constants/login_path";
import {LoginParams} from "../../context/type";

export const login = async (params: LoginParams) => {
    try {
        const result: any = await BaseApi.post(LOGIN_PATH.LOGIN, params);
        return result && result.info;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const loginOut = async () => {
    try {
        await BaseApi.post(LOGIN_PATH.LOGOUT)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getCode = async (username: string) => {
    try {
        await BaseApi.post(LOGIN_PATH.SEND_CODE, {
            username
        })
    } catch (e) {
        return Promise.reject(e);
    }
}
