// ** React Imports
import React, { MouseEvent, useState, ReactNode } from 'react'

import { Link } from 'react-router-dom';

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import { MenuItemProps } from '@mui/material/MenuItem'

// ** Icon Imports
import Icon from '../../components/icon'

// ** Hook Import
import { useSettings } from '../../hooks/useSettings'
import { DividerProps } from '@mui/material/Divider'
import { MenuProps } from '@mui/material/Menu'
import { IconButtonProps } from '@mui/material/IconButton'
import { IconProps } from '@iconify/react'
import Typography from "@mui/material/Typography";

type OptionMenuItemType = {
    text: ReactNode
    icon?: ReactNode
    linkProps?: any
    href?: string
    menuItemProps?: MenuItemProps
    divider?: never
    dividerProps?: never
}

type OptionDividerType = {
    divider: boolean
    dividerProps?: DividerProps
    href?: never
    icon?: never
    text?: never
    linkProps?: never
    menuItemProps?: never
}

type OptionType = string | OptionDividerType | OptionMenuItemType

type OptionsMenuType = {
    icon?: ReactNode
    options: OptionType[]
    leftAlignMenu?: boolean
    iconButtonProps?: IconButtonProps
    iconProps?: Omit<IconProps, 'icon'>
    menuProps?: Omit<MenuProps, 'open'>
    showLabel?: boolean
}

const MenuItemWrapper = ({ children, option }: { children: ReactNode; option: OptionMenuItemType }) => {
    if (option.href) {
        return (
            <Box
                component={Link}
                href={option.href}
                {...option.linkProps}
                sx={{
                    px: 4,
                    py: 1.5,
                    width: '100%',
                    display: 'flex',
                    color: 'inherit',
                    alignItems: 'center',
                    textDecoration: 'none'
                }}
            >
                {children}
            </Box>
        )
    } else {
        return <>{children}</>
    }
}

const OptionsMenu = (props: OptionsMenuType) => {
    // ** Props
    const { icon, options, menuProps, iconProps, leftAlignMenu, iconButtonProps, showLabel } = props

    // ** State
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    // ** Hook & Var
    const { settings } = useSettings()
    const { direction, platform } = settings

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {showLabel && platform ? <Typography style={{cursor: 'pointer'}} variant='h6'>{platform}</Typography> : ''}
            <IconButton aria-haspopup='true' onClick={handleClick} {...iconButtonProps}>
                {icon ? icon : <Icon icon='mdi:dots-vertical' {...iconProps} />}
            </IconButton>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                {...(!leftAlignMenu && {
                    anchorOrigin: { vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' },
                    transformOrigin: { vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }
                })}
                {...menuProps}
            >
                {options.map((option: OptionType, index: number) => {
                    if (typeof option === 'string') {
                        return (
                            <MenuItem key={index} onClick={handleClose}>
                                {option}
                            </MenuItem>
                        )
                    } else if ('divider' in option) {
                        return option.divider && <Divider key={index} {...option.dividerProps} />
                    } else {
                        return (
                            <MenuItem
                                key={index}
                                {...option.menuItemProps}
                                {...(option.href && { sx: { p: 0 } })}
                                onClick={e => {
                                    handleClose();
                                    option.menuItemProps && option.menuItemProps.onClick && option.menuItemProps.onClick(e);
                                }}
                            >
                                <MenuItemWrapper option={option}>
                                    {option.icon ? option.icon : null}
                                    {option.text}
                                </MenuItemWrapper>
                            </MenuItem>
                        )
                    }
                })}
            </Menu>
        </>
    )
}

export default OptionsMenu
