// ** React Imports
import {forwardRef, ReactElement, ReactNode, Ref, useEffect, useState} from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Fade, {FadeProps} from "@mui/material/Fade";
import {useForm} from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import Icon from "../icon";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
// ** Store Imports
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import {addUser} from "../../store/user";
import {UserType} from "../../pages/types/Menu";
import InputAdornment from '@mui/material/InputAdornment'

interface TableHeaderProps {
  value: string,
  modalTitle: string,
  editItem?: UserType,
  handleFilter: (val: string) => void,
  setModalTitle: (val: string) => void
}

const Transition = forwardRef(function Transition(
    props: FadeProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />
})

const TableHeader = (props: TableHeaderProps) => {
  // ** Props
  const { value, handleFilter, modalTitle, setModalTitle, editItem } = props

  const dispatch = useDispatch<AppDispatch>()

  // ** State
  const [open, setOpen] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, register, reset, formState: { errors } } = useForm();

  useEffect(() => {
    setOpen(!!modalTitle);
  }, [modalTitle])

  useEffect(() => {
    editItem ? reset(editItem) : reset();
  }, [editItem, reset])

  const handleDialogToggle = () => {
    setModalTitle('添加用户')
  }

  const onSubmit = (data: any) => {
    console.log("data",data);
    dispatch(addUser(data))
    handleClose();
    reset();
  }

  const handleClose = () => {
    setModalTitle('')
  }

  return (
    <>
      <Box
        sx={{ p: 5, pb: 3, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <TextField
          size='small'
          value={value}
          sx={{ mr: 4, mb: 2 }}
          placeholder='搜索用户'
          onChange={e => handleFilter(e.target.value)}
        />
        <Button sx={{ mb: 2 }} variant='contained' onClick={handleDialogToggle}>
          添加用户
        </Button>
      </Box>
      <Dialog
          fullWidth
          open={open}
          maxWidth='md'
          scroll='body'
          onClose={handleClose}
          TransitionComponent={Transition}
          onBackdropClick={handleClose}
          PaperProps={{
            style: {
              width: '540px', // 设置对话框的宽度
              maxWidth: '100%', // 可选，设置对话框的最大宽度
            },
          }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ pb: 8, px: { xs: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 }, position: 'relative' }}>
            <IconButton
                size='small'
                onClick={handleClose}
                sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
            >
              <Icon icon='mdi:close' />
            </IconButton>
            <Box sx={{ mb: 8, textAlign: 'center' }}>
              <Typography variant='h5' sx={{ mb: 3 }}>
                {modalTitle ? modalTitle : '添加用户'}
              </Typography>
            </Box>
            <Grid container spacing={6}>
              <Grid item sm={12} xs={12}>
                <TextField
                    fullWidth
                    label="用户名"
                    {...register('username', { required: '用户名不能为空' })}
                    placeholder='请输入用户名'
                    error={!!errors.name}
                    helperText={errors.name?.message as ReactNode}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                {!editItem && <TextField
                    fullWidth
                    label='密码'
                    {...register('password')}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='请输入密码'
                    error={!!errors.password}
                    helperText={errors.password?.message as ReactNode}
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Icon fontSize={20} icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                            </IconButton>
                          </InputAdornment>
                      )
                    }}
                />}
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                    fullWidth
                    type='number'
                    label='电话'
                    {...register('phone_number')}
                    placeholder='请输入电话'
                    error={!!errors.phone_number}
                    helperText={errors.phone_number?.message as ReactNode}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}>
            <Button type="submit" variant='contained' sx={{ mr: 1 }}>
              确定
            </Button>
            <Button variant='outlined' color='secondary' onClick={handleClose}>
              取消
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default TableHeader
