// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from '../../../components/icon'

// ** Type Import
import {BossItem, Settings} from '../../../context/settingsContext'

// ** Components
import Autocomplete from '../../../components/autocomplete/Autocomplete'
import ModeToggler from './ModeToggler'
import UserDropdown from './UserDropdown'
import TogglePlatform from './TogglePlatform';

interface Props {
    hidden: boolean
    settings: Settings
    toggleNavVisibility: () => void
    saveSettings: (values: Settings) => void,
    bossList: BossItem[]
}

const HomeHeader = (props: Props) => {
    // ** Props
    const { hidden, settings, saveSettings, toggleNavVisibility, bossList } = props

    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                {hidden && !settings.navHidden ? (
                    <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
                        <Icon icon='mdi:menu' />
                    </IconButton>
                ) : null}
                <Autocomplete hidden={hidden} settings={settings} />
            </Box>
            <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
                <TogglePlatform bossList={bossList} settings={settings} saveSettings={saveSettings} />
                <ModeToggler settings={settings} saveSettings={saveSettings} />
                <UserDropdown settings={settings} />
            </Box>
        </Box>
    )
}

export default HomeHeader
