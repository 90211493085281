import MuiTable from './table'
import MuiSelect from './select'
import MuiSwitches from './switches'
import MuiPopover from './popover'
import MuiDialog from './dialog'
import MuiCard from './card'
import MuiButton from './button'
import MuiDataGrid from './dataGrid'
import MuiInput from './input'
import MuiMenu from './menu'
import MuiDivider from './divider'
import MuiTypography from './typography'
import { Theme } from '@mui/material/styles'

import { Settings } from '../../context/settingsContext'

const Overrides = (theme: Theme, settings: Settings) => {
    const { skin } = settings
    const tables = MuiTable(theme)
    const dialog = MuiDialog(theme, skin)
    const switches = MuiSwitches(theme)
    const popover = MuiPopover(theme, skin)
    const cards = MuiCard(theme, skin)
    const button = MuiButton(theme)
    const dataGrid = MuiDataGrid(theme)
    const input = MuiInput(theme)
    const menu = MuiMenu(theme, skin)
    const divider = MuiDivider(theme)
    return Object.assign(
        tables,
        popover,
        dialog,
        cards,
        switches,
        button,
        divider,
        dataGrid,
        input,
        menu,
        MuiTypography,
        MuiSelect
    )
}

export default Overrides
