// ** React Imports
import React, { SyntheticEvent, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import AvatarGroup from '@mui/material/AvatarGroup'
import CardContent from '@mui/material/CardContent'
import DialogActions from '@mui/material/DialogActions'
import RoleStep from "./role/RoleStep"

// ** Icon Imports
import Icon from '../../../components/icon'
import CustomAvatar from '../../../components/avatar'
import {DialogContent} from "@mui/material";
import {makeUpToastParams} from "../../../utils/toast";
import {
  EditMenuTypes,
  RoleStepType,
  ServiceRoleMenuType,
  ServiceRoleType,
  UserRoleTypes,
  RoleUserType,
  UserType
} from "../../types/Menu";
import {useSnackbar} from "notistack";
import {useLoading} from "../../../useLoading";
import {compareChangeKeys} from "../../../utils/common";

type RoleCardsProps = {
  roleList: RoleUserType[],
  userList: UserType[],
  menuList: EditMenuTypes[],
  delete_role: (role_id: number) => void,
  edit_role: (params: ServiceRoleType) => Promise<number>,
  bind_user: (user_roles: UserRoleTypes[]) => void,
  bind_menu: (role_menus: ServiceRoleMenuType[]) => void,
  role_bind_user: (user_roles: UserRoleTypes[]) => void,
  update_user_list: () => void
}

const initialRoleParams: RoleStepType = {
  name: '',
  description: '',
  user_list: [],
  menu_ids: []
}

const RolesCards = (
    {
        roleList,
        userList,
        menuList,
        delete_role,
        edit_role,
        role_bind_user,
        update_user_list
    }: RoleCardsProps) => {
  // ** States
  const [open, setOpen] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<'添加' | '编辑'>('添加')
  const [roleStepParams, setRoleStepParams] = useState<RoleStepType>(initialRoleParams)
  const [compareStepParams, setCompareStepParams] = useState<RoleStepType>(initialRoleParams);
  const { enqueueSnackbar } = useSnackbar();
  const toggleLoading: any = useLoading();

  const handleClickOpen = () => setOpen(true)

  const handleSave = async () => {
    try {
      const keys = compareChangeKeys(compareStepParams, roleStepParams);
      const { name, description, user_list, menu_ids, id } = roleStepParams;
      toggleLoading(true)
      const promises = [];
      let new_id: number;
      if (keys.includes("name") || keys.includes("description")) {
        new_id = await edit_role({
          name,
          description,
          ...(id && { id })
        })
      }
      // 如果有user list，绑定user与role的关系
      if (user_list && user_list.length > 0) {
        const service_role_user_list: UserRoleTypes[] = user_list.map((v)=> {
          return {
            user_id: v.user_id.toString(),
            role_ids: [id ? id : new_id]
          }
        })
        promises.push(role_bind_user(service_role_user_list))
      }
      await Promise.all(promises);
      await update_user_list();
      // 如果有menu_ids，绑定角色和菜单的关系
      handleClose();
      toggleLoading(false);
    } catch (e: any) {
      enqueueSnackbar(e, makeUpToastParams('error'))
      toggleLoading(false);
    }
  }

  const handleEdit = async (item: RoleUserType) => {
    console.log("item", item);
    const params: RoleStepType = {
      menu_ids: [],
      id: item.role_id,
      name: item.role_name,
      description: item.role_description,
      user_list: item.users
    }
    setRoleStepParams(params)
    setCompareStepParams(params);
    setOpen(true)
  }

  const handleDelete = async (role_id: number) => {
    try {
      await delete_role(role_id);
    } catch (e: any) {
      enqueueSnackbar(e, makeUpToastParams('error'))
    }
  }

  const handleClose = () => {
    setOpen(false)
    setRoleStepParams(initialRoleParams)
    setCompareStepParams(initialRoleParams)
  }

  const renderCards = () =>
    roleList.map((item: RoleUserType) => (
      <Grid item xs={12} sm={4} lg={3} xl={3} key={item.role_id}>
        <Card>
          <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 36 }}>
              <Typography variant='body2'>{`Total ${item.users ? item.users.length : 0} users`}</Typography>
              <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32, fontSize: '0.875rem' } }}>
                {item.users && item.users.map((user: any) => (
                  // <Avatar key={index} alt={user.username} src={`/images/avatars/${user.username}`} />
                  <CustomAvatar key={user.username}>{user.username.slice(0, 2)}</CustomAvatar>
                ))}
              </AvatarGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography noWrap overflow="hidden" textOverflow="ellipsis" variant='h6'>{item.role_name}</Typography>
                <Typography
                  className="text-overflow"
                  variant='body2'
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault()
                    handleClickOpen()
                    setDialogTitle('编辑')
                  }}
                >
                  {item.role_description}
                </Typography>
              </Box>
              <div style={{flexShrink: 0}}>
                <IconButton onClick={() => handleEdit(item)} sx={{ color: 'text.secondary' }}>
                  <Icon icon='carbon:edit' style={{fontSize: 20}}/>
                </IconButton>
                <IconButton onClick={() => handleDelete(item.role_id)} sx={{ color: 'text.secondary' }}>
                  <Icon icon='carbon:trash-can' style={{fontSize: 20}}/>
                </IconButton>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ))

  return (
    <Grid container spacing={6} className='match-height'>
      {renderCards()}
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
        <Card
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            handleClickOpen()
            setDialogTitle('添加')
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={5}>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                <img width={65} height={130} alt='add-role' src={require('../../../assets/images/pages/pose_m1.png')} />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <CardContent>
                <Box sx={{ textAlign: 'right' }}>
                  <Button
                    variant='contained'
                    sx={{ mb: 3, whiteSpace: 'nowrap' }}
                    onClick={() => {
                      handleClickOpen()
                      setDialogTitle('添加')
                    }}
                  >
                    添加角色
                  </Button>
                  <Typography>点击添加新的角色</Typography>
                </Box>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Dialog fullWidth maxWidth='md' scroll='body' onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant='h5' component='span'>
            {`${dialogTitle}角色`}
          </Typography>
          <Typography variant='body2'>请添加角色并分配权限</Typography>
        </DialogTitle>
        <DialogContent sx={{p: {xs: 6, sm: 12}}}>
          <RoleStep
              userList={userList}
              menuList={menuList}
              roleStepParams={roleStepParams}
              changeParams={setRoleStepParams}
          />
        </DialogContent>
        <DialogActions sx={{ pt: 0, display: 'flex', justifyContent: 'center' }}>
          <Box className='demo-space-x'>
            <Button
                size='large'
                type='submit'
                variant='contained'
                startIcon={
                  <Icon icon='carbon:save' style={{fontSize: 18}}/>
                }
                onClick={handleSave}
            >
              保存
            </Button>
            <Button
                size='large'
                color='secondary'
                variant='outlined'
                startIcon={
                  <Icon icon='material-symbols:cancel' style={{fontSize: 18}}/>
                }
                onClick={handleClose}
            >
              取消
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default RolesCards
