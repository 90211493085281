import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Icon from '../../../../components/icon'
import Card from '@mui/material/Card'
import {styled} from '@mui/material/styles'
import StepperWrapper from "../../../../components/step/Stepper";
import StepLabel from '@mui/material/StepLabel'
import MuiStep, {StepProps} from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import CardContent, {CardContentProps} from '@mui/material/CardContent'
import StepperCustomDot from "../../../../components/step/StepperCustomDot"
import {
    EditMenuTypes,
    RoleStepType,
    UserType
} from "../../../types/Menu"
import RoleUserItem from "./RoleUserItem"
import RoleMsgItem from "./RoleMsgItem"
import RoleMenuItem from "./RoleMenuItem"

const StepperHeaderContainer = styled(CardContent)<CardContentProps>(({theme}) => ({
    minWidth: 300,
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('lg')]: {
        borderRight: 0,
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}))

const steps = [
    {
        title: '角色信息（必填）',
        subtitle: '请输入角色名称，备注，权重',
        icon: 'mdi:account-outline'
    },
    {
        title: '角色人员（选填）',
        subtitle: '请选择属于该角色的用户',
        icon: 'mdi:account-outline'
    },
    {
        title: '角色菜单（选填）',
        subtitle: '请选择该角色可访问的菜单',
        icon: 'mdi:account-outline'
    }
]

const Step = styled(MuiStep)<StepProps>(({theme}) => ({
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(4)
    },
    '& .MuiStepLabel-root': {
        padding: 0
    }
}))

type RoleStepProps = {
    userList: UserType[],
    menuList: EditMenuTypes[],
    roleStepParams: RoleStepType,
    changeParams: (params: RoleStepType) => void
}

const RoleStep = ({userList, menuList, changeParams, roleStepParams}: RoleStepProps) => {
    const [activeStep, setActiveStep] = useState<number>(0)
    const [nameErrorText, setNameErrorText] = useState<string>('')
    const [userListErrorText, setUserListErrorText] = useState<string>('')
    const [userMenuErrorText, setMenuListErrorText] = useState<string>('')
    const [filterUserList, setFilterUserList] = useState<UserType[]>([])

    useEffect(() => {
        const list = userList.filter(
            (v) => v.roles.length === 0 ||
                v.roles.find(
                    (r) => r.role_id === roleStepParams.id)
        )
        setFilterUserList(list)
    }, [roleStepParams.id])
    const handleNext = () => {
        const {name, menu_ids, user_list} = roleStepParams;
        if (activeStep === 0) {
            if (!name) {
                setNameErrorText('请输入角色名称');
                return;
            }
        } else if (activeStep === 1) {
            if (user_list.length === 0 && filterUserList.length > 0) {
                setUserListErrorText('请至少选择一个用户');
                return;
            }
        } else {
            if (menu_ids.length === 0 && menuList.length > 0) {
                setMenuListErrorText('请至少选择一个菜单');
                return;
            }
        }
        setActiveStep(activeStep + 1)
    }
    const handlePrev = () => {
        if (activeStep !== 0) {
            setActiveStep(activeStep - 1)
        }
    }

    const clearErrorText = (type: 'name' | 'user' | 'menu', value: string) => {
        console.log("clearErrorText", type);
        if (value) {
            switch (type) {
                case 'name':
                    return setNameErrorText('');
                case "menu":
                    return setMenuListErrorText('');
                case "user":
                    return setUserListErrorText('');
            }
        }
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <RoleMsgItem
                    roleStepParams={roleStepParams}
                    changeParams={changeParams}
                    nameErrorText={nameErrorText}
                    clearErrorText={clearErrorText}
                />
            case 1:
                return <RoleUserItem
                    roleStepParams={roleStepParams}
                    changeParams={changeParams}
                    userList={filterUserList}
                    clearErrorText={clearErrorText}
                />
            case 2:
                return <RoleMenuItem
                    menuList={menuList}
                    roleStepParams={roleStepParams}
                    changeParams={changeParams}
                    clearErrorText={clearErrorText}
                />
            default:
                return null
        }
    }

    const renderContent = () => {
        return getStepContent(activeStep)
    }

    const renderFooter = () => {
        const stepCondition = activeStep === steps.length - 1

        return (
            <Box sx={{mt: 4, display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    color='secondary'
                    variant='outlined'
                    onClick={handlePrev}
                    disabled={activeStep === 0}
                    startIcon={<Icon icon='mdi:arrow-left'/>}
                >
                    上一步
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={stepCondition}
                    endIcon={<Icon icon='mdi:arrow-right'/>}
                    onClick={handleNext}
                >
                    下一步
                </Button>
            </Box>
        )
    }

    return (
        <Card sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}}}>
            <StepperHeaderContainer>
                <StepperWrapper sx={{height: '100%', '& .MuiStepLabel-label': {cursor: 'pointer'}}}>
                    <Stepper connector={<></>} activeStep={activeStep} orientation='vertical'>
                        {steps.map((step, index) => {
                            return (
                                <Step
                                    key={index}
                                    onClick={() => setActiveStep(index)}
                                    sx={{'&.Mui-completed + svg': {color: 'primary.main'}}}
                                >
                                    <StepLabel StepIconComponent={StepperCustomDot}>
                                        <div className='step-label'>
                                            <Typography className='step-number'>{`0${index + 1}`}</Typography>
                                            <div>
                                                <Typography className='step-title'>{step.title}</Typography>
                                                <Typography className='step-subtitle'>{step.subtitle}</Typography>
                                            </div>
                                        </div>
                                    </StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                </StepperWrapper>
            </StepperHeaderContainer>
            <div style={{flex: 1}}>
                <CardContent>
                    {renderContent()}
                    {renderFooter()}
                </CardContent>
            </div>
        </Card>
    )
}

export default RoleStep;
