import { styled } from '@mui/material/styles'
import UserIcon from '../../../components/icon/UserIcon'
import OptionsMenu from '../../../components/option-menu'
import {useEffect, useState} from "react";
import { useSettings } from "../../../hooks/useSettings"
import {fetch_menu_list} from "../../../api/service/common";
import {useSnackbar} from "notistack";
import "./test.scss"
import {makeUpToastParams} from "../../../utils/toast";

const StyledNode = styled('div')(({ theme }) => ({
    padding: '10px 15px',
    borderRadius: '8px',
    display: 'inline-block',
    background: '#fff',
    color: 'rgba(58, 53, 65, 0.87)',
    boxShadow: '0px 2px 10px 0px rgba(58, 53, 65, 0.1)',
    '& .inner': {
        display: 'flex',
        alignItems: 'center',
        '.label': {
            marginLeft: '5px'
        }
    }
}))

const mockList = [{
    boss: "cg",
    icon: "",
    id: 25,
    name: "配置",
    parent_id: 0,
    parent_name: "",
    sort_no: 3,
    type: 0,
    url: "base",
    children: [{
        boss: "cg",
        icon: "",
        id: 251,
        name: "配置",
        parent_id: 0,
        parent_name: "",
        sort_no: 3,
        type: 0,
        url: "base",
    }, {
        boss: "cg",
        icon: "",
        id: 252,
        name: "配置",
        parent_id: 0,
        parent_name: "",
        sort_no: 3,
        type: 0,
        url: "base",
    }]
}]

// mdi:email-outline
const ExampleTree = () => {
    const { settings } = useSettings()
    const [menus, setMenus] = useState<any[]>([])
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        settings.platform && getMenuList();
    }, [settings.platform])

    const getMenuList = async () => {
        try {
            console.log("getMenuList")
            const menu_result: any = await fetch_menu_list(
                settings.platform
            );
            setMenus(menu_result);
            console.log("menu_result", menu_result);
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const nodeItem = (item: any, editable = true, bottom = false) => {
        return (
            <StyledNode>
                <div className="inner">
                    <UserIcon icon={item.icon} />
                    <div className="label">{item.name}</div>
                    <OptionsMenu
                        iconButtonProps={{ size: 'small' }}
                        options={(editable && !bottom) ? [
                            {
                                text: '添加子菜单',
                                menuItemProps: {
                                    onClick: () => {
                                        console.log("add");
                                    }
                                }
                            },
                            '编辑菜单',
                            { divider: true },
                            { text: '删除菜单', menuItemProps: { sx: { color: 'error.main' } } }
                        ] : (editable && bottom) ? [
                            '编辑菜单',
                            { divider: true },
                            { text: '删除菜单', menuItemProps: { sx: { color: 'error.main' } }}
                        ] : ['添加子菜单']}
                    />
                </div>
            </StyledNode>
        )
    }

    return (
        <div className="outer-container">
            {
                nodeItem({name: '菜单', icon: 'mdi:home-outline'} , false, false)
            }
            <div className="inner-container">
                <div className="inner">
                    <StyledNode>test</StyledNode>
                    <div className="inner-container">
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                    </div>
                </div>
                <div className="inner">
                    <StyledNode>test</StyledNode>
                    <div className="inner-container">
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                    </div>
                </div>
                <div className="inner">
                    <StyledNode>test</StyledNode>
                    <div className="inner-container">
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                    </div>
                </div>
                <div className="inner">
                    <StyledNode>test</StyledNode>
                    <div className="inner-container">
                        <div className="inner single">
                            <StyledNode>test</StyledNode>
                        </div>
                    </div>
                </div>
                <div className="inner">
                    <StyledNode>test</StyledNode>
                    <div className="inner-container">
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                    </div>
                </div>
                <div className="inner">
                    <StyledNode>test</StyledNode>
                    <div className="inner-container">
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                        <div className="inner">
                            <StyledNode>test</StyledNode>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ExampleTree;
