import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import TabContext from '@mui/lab/TabContext'
import {ChangeEvent, SyntheticEvent, useState} from 'react'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import AceEditor from "react-ace";
import { styled } from '@mui/material/styles'
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-dracula";
import Icon from "../components/icon";
import DiffDialog from "./DiffDialog";
import {getPlatformPlans, verifySetting, planConfigDetail} from "../api/service/diff_setting";
import {Plan, PlanInfoTypes} from "./types/Diff";
import jsonlint from 'jsonlint-mod';
import {useSnackbar} from "notistack";
import { useLoading } from '../useLoading';
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import {makeUpToastParams} from "../utils/toast";

const StyledTabs = styled((props: any) => (
    <TabList
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'column'
    },
    '& .MuiTabs-indicatorSpan': {
        height: 26,
        width: '100%',
        backgroundColor: '#9155FD',
    },
});

interface Props {
    configContent: any;
    secondTab: string,
    firstTab: string,
    setFirstTab: (tab: string) => void,
    setSecondTab: (tab: string) => void,
    jsonContent: string,
    onConfigSave: () => void,
    onSaveConfigItem: (text: string) => void,
    onJsonChange: (json: string) => void,
    platform: string,
    plan: string,
    planList: Plan[],
    info: PlanInfoTypes,
}

const PlanDiffMsg = (
    {
        configContent,
        secondTab,
        firstTab,
        setFirstTab,
        setSecondTab,
        jsonContent,
        onConfigSave,
        onJsonChange,
        platform,
        plan,
        onSaveConfigItem,
        planList,
        info
    } : Props
) => {
    const [dialogShow, setDialogShow] = useState(false);
    const [tabModalShow, setTabModalShow] = useState<boolean>(false)
    const [leftPlanList, setLeftPlanList] = useState<Plan[]>([]);
    const [modalTitle, setModalTitle] = useState("");
    const [newConfig, setNewConfig] = useState("");
    const [rightJson, setRightJson] = useState("");
    const [leftJson, setLeftJson] = useState("");
    const [comparePlan, setComparePlan] = useState("")
    const { enqueueSnackbar } = useSnackbar();
    const toggleLoading: any = useLoading();

    const handleClose = () => {
        setTabModalShow(false);
        setNewConfig("");
    }

    const changeComparePlan = async (plan: string, all: boolean) => {
        try {
            setComparePlan(plan);
            toggleLoading(true);
            await getLeftJson(plan, all)
            toggleLoading(false);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error', 3000));
        }
    }

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        if (newValue !== "tab_add") {
            setSecondTab(newValue)
        } else {
            setTabModalShow(true);
        }
    }

    const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFirstTab((event.target as HTMLInputElement).value)
    }

    const compareSetting = async () => {
        setComparePlan("base");
        toggleLoading(true);
        Promise.all([
            getLeftJson(plan, true, true),
            getLeftJson("base", true),
            getFilterPlans()
        ]).then(() => {
            setModalTitle("配置比较");
            setDialogShow(true);
            toggleLoading(false);
        }).catch((e) => {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        })
    }

    const getFilterPlans = async (addSelf: boolean = false) => {
        const plan_list: Plan[] = await getPlatformPlans(platform, plan);
        if (addSelf) {
            const item = planList.find((v) => v.name === plan);
            setLeftPlanList(item ? [item, ...plan_list] : plan_list);
        } else {
            setLeftPlanList(plan_list);
        }
    }

    const getRightJson = async (base_plan: string) => {
        try {
            jsonContent && jsonlint.parse(jsonContent);
            const diff_config = [{
                platform_type: `${platform}##${firstTab}`,
                setting_name: `${secondTab}`,
                setting_value: jsonContent ? JSON.parse(jsonContent) : {}
            }]
            const diff_result: any = await verifySetting(platform, base_plan, JSON.stringify(diff_config));
            const right_json_str = diff_result[firstTab][secondTab];
            setRightJson(JSON.stringify(right_json_str, null, 2));
        } catch (e) {
            if (e instanceof Error) {
                return Promise.reject(e.message);
            } else {
                return Promise.reject(e);
            }
        }
    }

    const getLeftJson = async (plan: string, all: boolean = false, isRight: boolean = false) => {
        try {
            const result: any = await planConfigDetail(platform, plan);
            const leftJson = all ? result : result[firstTab][secondTab];
            isRight ?
                setRightJson(JSON.stringify(leftJson, null, 2)) :
                setLeftJson(JSON.stringify(leftJson, null, 2))
        } catch (e) {
            return Promise.reject(e);
        }
    }

    const saveConfigItem = async () => {
        try {
            await onSaveConfigItem(newConfig);
            handleClose();
        } catch (e) {
            handleClose();
        }
    }

    const onSave = async () => {
        setComparePlan(plan);
        const base_msg =
            configContent &&
            configContent[firstTab] &&
            configContent[firstTab][secondTab];
        if (!jsonContent && !base_msg) {
            enqueueSnackbar("请先输入json配置", makeUpToastParams('error'));
            return;
        }
        toggleLoading(true);
        Promise.all([getRightJson(info.parent_plan), getLeftJson(plan), getFilterPlans(true)]).then(() => {
            setModalTitle("配置校验");
            setDialogShow(true);
            toggleLoading(false);
        }).catch((e) => {
            enqueueSnackbar(e, makeUpToastParams('error'));
            toggleLoading(false);
        })
    }

    function onChange(newValue: string) {
        onJsonChange(newValue);
    }

    return (
        <Grid container spacing={6} sx={{height: '100%', mt: 0}}>
            <Grid item xs={12}>
                <Card sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <CardHeader
                        title='差异配置'
                        action={
                            <>
                                <Button
                                    startIcon={
                                        <Icon icon='carbon:save' style={{fontSize: 18}}/>
                                    }
                                    variant='contained'
                                    onClick={onSave}
                                >
                                    保存
                                </Button>
                                <Button
                                    startIcon={
                                        <Icon icon='carbon:compare' style={{fontSize: 18}}/>
                                    }
                                    sx={{
                                        ml: 4
                                    }}
                                    variant='contained'
                                    onClick={compareSetting}
                                >
                                    预览
                                </Button>
                            </>
                        }
                    />
                    <RadioGroup sx={{ml: 5}} row aria-label='controlled' name='controlled' value={firstTab} onChange={handleCategoryChange}>
                        {Object.keys(configContent).reverse().map((v: string) => (
                            <FormControlLabel key={v} value={v} control={<Radio />} label={v} />
                        ))}
                    </RadioGroup>
                    <TabContext value={secondTab ? secondTab : 'tab_add'}>
                        <Box sx={{ display: 'flex', pr: 3, pb: 3, flex: 1 }}>
                            <StyledTabs
                                sx={{flexShrink: 0, width: '100px'}}
                                orientation='vertical'
                                onChange={handleChange}
                                >
                                {
                                    firstTab &&
                                    secondTab &&
                                    configContent &&
                                    Object.keys(configContent[firstTab]).includes(secondTab) &&
                                    Object.keys(configContent[firstTab]).map((v) => (
                                    <Tab key={v} value={v} label={v} style={{textTransform: 'none'}}/>
                                ))}
                                {
                                    firstTab &&
                                    secondTab &&
                                    configContent && <Tab key="tab_add" value="tab_add" icon={
                                    <Icon fontSize={20} icon="mdi:plus" />
                                } />}
                            </StyledTabs>
                            <TabPanel value={
                                secondTab
                            } sx={{flex: 1}}>
                                <AceEditor
                                    style={{width: '100%', height: '100%'}}
                                    mode="json"
                                    theme="dracula"
                                    name="my-editor"
                                    value={jsonContent}
                                    fontSize={14}
                                    onChange={onChange}
                                    showPrintMargin={false}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        useWorker: false,
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2,
                                        useSoftTabs: false,
                                        wrap: true
                                    }}
                                />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Card>
            </Grid>
            <DiffDialog
                modalTitle={modalTitle}
                show={dialogShow}
                setShow={setDialogShow}
                planList={leftPlanList}
                rightJson={rightJson}
                leftJson={leftJson}
                comparePlan={comparePlan}
                onConfigSave={onConfigSave}
                setComparePlan={changeComparePlan}
                currentPlan={plan}
            />
            <Dialog
                open={tabModalShow}
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                PaperProps={{
                    style: {
                        width: '360px', // 设置对话框的宽度
                        maxWidth: '100%', // 可选，设置对话框的最大宽度
                    },
                }}
            >
                <DialogTitle id='form-dialog-title'>新增配置项</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 3 }}>
                        {`您将在${firstTab}下增加配置项`}
                    </DialogContentText>
                    <TextField
                        id='setting'
                        value={newConfig}
                        onChange={(e: any) => (
                            setNewConfig(e.target.value)
                        )}
                        fullWidth
                        autoFocus
                        label='配置名称'
                    />
                </DialogContent>
                <DialogActions className='dialog-actions-dense'>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={saveConfigItem}>保存</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default PlanDiffMsg;
