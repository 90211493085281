import * as React from 'react';
import { css, cx } from '@emotion/css';
import type { ReactNode } from 'react';

export interface TreeNodeProps {
    /**
     * The node label
     * */
    label: React.ReactNode;
    className?: string;
    children?: ReactNode;
}

const verticalLine = css`
  content: '';
  position: absolute;
  left: 0;
  width: var(--tree-line-width);
  box-sizing: border-box;
`;

const childrenContainer = css`
  display: flex;
  padding-inline-start: 0;
  margin: 0;
  padding-left: var(--tree-line-width);
  position: relative;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  ::before {
    ${verticalLine};
    top: calc(50% - var(--tree-line-height) / 2);
    height: 0;
    border-top: var(--tree-line-height) var(--tree-node-line-style)
      var(--tree-line-color);
  }
`;

const node = css`
  flex: auto;
  text-align: center;
  list-style-type: none;
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  height: auto;
  padding: var(--tree-node-padding) 0 var(--tree-node-padding) var(--tree-line-width);
`;

const nodeLines = css`
  ::before,
  ::after {
    ${verticalLine};
    bottom: calc(50% + var(--tree-line-height) / 2);
    height: 50%;
    border-left: var(--tree-line-height) var(--tree-node-line-style)
      var(--tree-line-color);
  }
  ::after {
    top: calc(50% - var(--tree-line-height) / 2);
    border-top: var(--tree-line-height) var(--tree-node-line-style)
      var(--tree-line-color);
  }

  :only-of-type {
    padding: 0;
    ::after,
    :before {
      display: none;
    }
  }

  :first-of-type {
    ::before {
      border: 0 none;
    }
    ::after {
      border-radius: var(--tree-line-border-radius) 0 0 0;
    }
  }

  :last-of-type {
    ::before {
      border-bottom: var(--tree-line-height) var(--tree-node-line-style)
        var(--tree-line-color);
      border-radius: 0 0 0 var(--tree-line-border-radius);
    }
    ::after {
      border: 0 none;
    }
  }
`;

const TreeNode = ({children, label, className}: TreeNodeProps) => {
    return (
        <div className={cx(node, nodeLines, className)}>
            {label}
            {React.Children.count(children) > 0 && (
                <div className={childrenContainer}>{children}</div>
            )}
        </div>
    )
}

export default TreeNode;
