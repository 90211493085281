import nativeStorage from "../utils/storage/web_storage";
import { Module } from "./module";

export class CacheModule extends Module {
  private readonly cache_name: string;
  constructor(
    cache_name: string,
    defaultValue: any,
    manual_init: boolean = false
  ) {
    super(defaultValue);
    this.cache_name = cache_name;
    !manual_init && this.init(cache_name);
  }

  public async setCache(value: any) {
    try {
      this.set(value);
      await nativeStorage.set(this.cache_name, value);
    } catch (error) {
      console.log(error);
    }
  }

  public async init(cache_name: string) {
    const cache = await nativeStorage.get(cache_name);
    if (cache) {
      this.set(cache);
    }
    return Promise.resolve(cache);
  }
}
