// ** React Imports
import { ElementType } from 'react'

// ** Next Imports
import { Link, useLocation } from 'react-router-dom';

// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import { styled, useTheme } from '@mui/material/styles'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'

// ** Configs Import
import themeConfig from '../../../configs/themeConfig'

// ** Types
import { NavLink, NavGroup } from '../../../layouts/types'
import { Settings } from '../../../context/settingsContext'

// ** Custom Components Imports
import UserIcon from '../../../components/icon/UserIcon'

// ** Util Import
import { handleURLQueries } from '../utils'

interface Props {
  parent?: boolean
  item: NavLink
  navHover?: boolean
  settings: Settings
  navVisible?: boolean
  collapsedNavWidth: number
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  isSubToSub?: NavGroup | undefined
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; to: string; target?: '_blank' | undefined }
>(({ theme }) => ({
  width: '100%',
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    '&, &:hover': {
      boxShadow: theme.shadows[3],
      backgroundImage: `linear-gradient(98deg, ${theme.palette.customColors.primaryGradient}, ${theme.palette.primary.main} 94%)`
    },
    '& .MuiTypography-root, & .MuiListItemIcon-root': {
      color: `${theme.palette.common.white} !important`
    }
  }
}))

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
})

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}: Props) => {
  // ** Hooks
  const theme = useTheme()
  const router = useLocation()

  // ** Vars
  const { mode, navCollapsed } = settings

  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

  const conditionalColors = () => {
    if (mode === 'semi-dark') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        '&:hover': {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`
        }
      }
    } else return {}
  }

  const isNavLinkActive = () => {
    if (item.url && router.pathname.slice(1) === item.url) {
      return true
    } else {
      return false
    }
  }

  return (
      <ListItem
          disablePadding
          className='nav-link'
          disabled={item.disabled || false}
          sx={{ mt: 1.5, px: '0 !important' }}
      >
        <MenuNavLink
            component={Link}
            {...(item.disabled && { tabIndex: -1 })}
            className={isNavLinkActive() ? 'active' : ''}
            to={item.url === undefined ? '/' : `${item.url}`}
            {...(item.openInNewTab ? { target: '_blank' } : null)}
            onClick={e => {
              if (item.url === undefined) {
                e.preventDefault()
                e.stopPropagation()
              }
              if (navVisible) {
                toggleNavVisibility()
              }
            }}
            sx={{
              py: 2.25,
              ...conditionalColors(),
              ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
              pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24) / 8 : 5.5,
              pr: navCollapsed && !navHover ? ((collapsedNavWidth - navigationBorderWidth - 24) / 2 - 5) / 4 : 3.5
            }}
        >
          {isSubToSub ? null : (
              <ListItemIcon
                  sx={{
                    color: 'text.primary',
                    transition: 'margin .25s ease-in-out',
                    minWidth: 0,
                    ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2.5 }),
                    ...(parent ? { ml: 1.25, mr: 3.75 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
                    '& svg': {
                      fontSize: '0.875rem',
                      ...(!parent ? { fontSize: '1.5rem' } : {}),
                      ...(parent && item.icon ? { fontSize: '0.875rem' } : {})
                    }
                  }}
              >
                <UserIcon icon={icon ? icon : 'ri:menu-2-line'} fontSize={18}/>
              </ListItemIcon>
          )}

          <MenuItemTextMetaWrapper
              sx={{
                ...(isSubToSub ? { ml: 9 } : {}),
                ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
              }}
          >
            <Typography
                {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                  noWrap: true
                })}
            >
              {item.name}
            </Typography>
            {item.badgeContent ? (
                <Chip
                    label={item.badgeContent}
                    color={item.badgeColor || 'primary'}
                    sx={{
                      ml: 1.25,
                      height: 20,
                      fontWeight: 500,
                      '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' }
                    }}
                />
            ) : null}
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </ListItem>
  )
}

export default VerticalNavLink
