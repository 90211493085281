import { useEffect, useState, ReactNode, createContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getCode, login, loginOut } from "../api/service/login";
import { AuthValuesType, UserDataType, LoginParams, ErrCallbackType } from "./type";
import WebLocalStorageWorker from "../utils/storage/web_storage";
import { USER_INFO } from "../utils/storage/constant";

type Props = {
    children: ReactNode
}
const defaultProvider: AuthValuesType = {
    user: null,
    loading: true,
    setUser: () => null,
    setLoading: () => Boolean,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    sendCode: () => void 0
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }: Props) => {
    const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
    const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

    const router = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const userInfo = WebLocalStorageWorker.get(USER_INFO);
        userInfo && setUser({
            username: userInfo.username,
            token: userInfo.token
        })
    }, [])

    const handleLogin = async (params: LoginParams, errorCallback?: ErrCallbackType) => {
        try {
            console.log("handleLogin", params);
            const result: any = await login({
                username: params.username,
                password: params.password,
                phone_code: params.phone_code
            });
            setUser(result)
            WebLocalStorageWorker.set(
                USER_INFO,
                {
                    password: params.password,
                    remember: params.rememberMe,
                    ...result
                }
            )
            const returnUrl = router.state && router.state.returnUrl;
            navigate(returnUrl ? returnUrl : "/config", {
                replace: true
            })
        } catch (e) {
            return Promise.reject(e);
        }
    }

    const sendVerification = async (username: string) => {
        console.log("sendVerification");
        await getCode(username);
    }

    const handleLogout = async () => {
        try {
            await loginOut();
            setUser(null)
            window.localStorage.removeItem('userData')
            navigate('/login', {replace: true})
        } catch (e) {
            // return Promise.reject(e);
        }
    }

    const values = {
        user,
        loading,
        setUser,
        setLoading,
        login: handleLogin,
        logout: handleLogout,
        sendCode: sendVerification
    }

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
