// ** React Imports
import { ReactNode, useEffect } from 'react'

// ** Next Imports
import { useLocation, useNavigate } from 'react-router-dom';

// ** Hooks Import
import { useAuth } from '../hooks/useAuth'

import WebLocalStorageWorker from "../utils/storage/web_storage";
import {USER_INFO} from "../utils/storage/constant";

interface AuthGuardProps {
  children: ReactNode
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props
  const auth = useAuth()
  const router = useLocation()
  const navigate = useNavigate()

  useEffect(
    () => {
      if (auth.user === null && !WebLocalStorageWorker.get(USER_INFO)) {
        console.log("router", router)
        if (!router.pathname.includes("login")) {
          navigate('/login', {
            replace: true, state: {
              returnUrl: router.pathname
            }
          });
        }
      } else if (router.pathname === "/") {
        navigate('/config')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.pathname]
  )

  return <>{children}</>
}

export default AuthGuard
