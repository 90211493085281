// ** React Imports
import { ReactNode } from 'react'

// ** MUI Components
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'

// ** Layout Import
import BlankLayout from '../components/layout/BlankLayout'

import { useNavigate } from 'react-router-dom';

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '90vw'
    }
}))

const Img = styled('img')(({ theme }) => ({
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
        height: 450,
        marginTop: theme.spacing(10)
    },
    [theme.breakpoints.down('md')]: {
        height: 400
    },
    [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(13)
    }
}))

const Error401 = () => {
    return (
        <Box className='content-center'>
            <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <BoxWrapper>
                    <Typography variant='h1'>401</Typography>
                    <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
                        您没有权限
                    </Typography>
                    <Typography variant='body2'>请联系管理员分配权限</Typography>
                </BoxWrapper>
                <Img height='487' alt='error-illustration' src={require('../assets/images/pages/401.png')} />
                {/*<Button onClick={() => {navigate(-1);}} variant='contained' sx={{ px: 5.5, mt: 8 }}>*/}
                {/*    返回列表*/}
                {/*</Button>*/}
            </Box>
            {/*<FooterIllustrations />*/}
        </Box>
    )
}

Error401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default Error401
