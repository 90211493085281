import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import { styled, useTheme } from '@mui/material/styles'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'
import {Settings} from "../../context/settingsContext"
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { hexToRGBA } from '../../utils/hex-to-rgba'

interface PageHeaderProps {
    toggleNavVisibility: () => void
    appBarContent: any
    settings: Settings
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    transition: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 6),
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    minHeight: theme.mixins.toolbar.minHeight,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
    width: '100%',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: `${theme.spacing(0)} !important`,
    minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
    transition: 'padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out'
}))

const PageHeader = (props: PageHeaderProps) => {
    const theme = useTheme()
    const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })
    const { settings, appBarContent: userAppBarContent } = props
    const { appBar, contentWidth, appBarBlur, skin } = settings
    console.log("contentWidth", contentWidth);

    const appBarFixedStyles = () => {
        return {
            px: `${theme.spacing(5)} !important`,
            boxShadow: skin === 'bordered' ? 0 : 3,
            ...(appBarBlur && { backdropFilter: 'blur(8px)' }),
            backgroundColor: hexToRGBA(theme.palette.background.paper, appBarBlur ? 0.85 : 1),
            ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}`, borderTopWidth: 0 })
        }
    }
    return (
        <AppBar
            elevation={0}
            color='default'
            className='layout-navbar'
            position={appBar === 'fixed' ? 'sticky' : 'static'}
        >
            <Toolbar
                className='navbar-content-container'
                sx={{
                    ...(appBar === 'fixed' && scrollTrigger && { ...appBarFixedStyles() }),
                    ...(contentWidth === 'boxed' && {
                        '@media (min-width:1440px)': { maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)` }
                    })
                }}
            >
                {(userAppBarContent && userAppBarContent(props)) || null}
            </Toolbar>
        </AppBar>
    )
}

export default PageHeader;
