export const PLATFORM_LIST = "/api/v1/diff_config/platforms";
export const EDIT_PLAN = "/api/v1/diff_config/add_or_update_user_group";
export const GET_BASE_FILTER_MSG = "/api/v1/diff_config/af_infos";
export const GET_PLATFORM_SWITCH_STATUS = "/api/v1/diff_config/get_platform_switch_status";
export const CHANGE_PLATFORM_SWITCH_STATUS = "/api/v1/diff_config/change_platform_switch_status";
export const HISTORY = "/api/v1/diff_config/history";
export const DEL_PLAN = "/api/v1/diff_config/del_user_group";
export const GET_PLAN_LIST = "/api/v1/diff_config/plan_list";
export const PLAN_CONFIG_DETAIL = "/api/v1/diff_config/diff_config_detail";
export const GET_PLAN_DETAIL = "/api/v1/diff_config/plan_content";
export const VERIFY_CONFIG = "/api/v1/diff_config/validate_cover_config";
export const SAVE_CONFIG = "/api/v1/diff_config/add_or_update";
export const ADD_CONFIG_ITEM = "/api/v1/config/config";
