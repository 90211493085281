import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {DataGrid} from '@mui/x-data-grid'
import React, {useState, useEffect} from "react"
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import CustomChip from '../components/chip'
import {ThemeColor} from "../layouts/types"
import TextField from '@mui/material/TextField'
import { getList } from "../api/service/diff_setting";
import {useSnackbar} from "notistack";
import {useLoading} from "../useLoading";
import { Link } from 'react-router-dom';
import {makeUpToastParams} from "../utils/toast";

interface CellType {
    row: any
}

interface UserStatusType {
    [key: string]: ThemeColor
}

const userStatusObj: UserStatusType = {
    on: 'success',
    plan: 'primary',
    off: 'secondary',
    plan_off: 'secondary'
}

const StyledLink = styled(Link)(({theme}) => ({
    fontWeight: 600,
    fontSize: '1rem',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {
        color: theme.palette.primary.main
    }
}))

const columns = [
    {
        flex: 0.2,
        minWidth: 160,
        maxWidth: 260,
        field: 'platform',
        wrap: 'wrap',
        headerName: 'platform',
        renderCell: ({row}: CellType) => {
            const {platform} = row
            return (
                <StyledLink to={`/different_config/${platform}`}>{platform}</StyledLink>
            )
        }
    },
    {
        flex: 0.4,
        headerName: 'Plan',
        field: 'plans',
        wrap: 'wrap',
        cellClassName: 'my-cell-class',
        sortable: false,
        filterable: false,
        renderCell: ({row}: CellType) => {
            const {plans, platform} = row;
            return (
                plans.map((v: any, index: number) => {
                    return <Link style={{cursor: 'pointer'}} key={index} to={`/different_config/${platform}/${v.name}`}><CustomChip
                        skin='light'
                        style={{marginRight: 10}}
                        size='small'
                        label={v.name + (v.alias ? "-" + v.alias : "")}
                        color={v.switch === 'on' ? userStatusObj['plan'] : userStatusObj['plan_off']}
                        sx={{textTransform: 'capitalize', cursor: 'pointer'}}
                    /></Link>
                })
            )
        }
    },
    {
        flex: 0.1,
        minWidth: 110,
        field: 'switch',
        headerName: '状态',
        filterable: false,
        renderCell: ({row}: CellType) => {
            return (
                <CustomChip
                    skin='light'
                    size='small'
                    label={row.switch}
                    color={userStatusObj[row.switch]}
                    sx={{textTransform: 'capitalize'}}
                />
            )
        }
    }
]

const DiffList = () => {
    const [value, setValue] = useState<string>('')
    const [list, setList] = useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const toggleLoading: any = useLoading();

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        try {
            toggleLoading(true);
            const result: any = await getList();
            toggleLoading(false);
            setList(result);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const getRowId = (row: any) => {
        return row.platform; // 使用 name 属性作为唯一标识符
    };

    const handleFilter = (v: string) => {
        setValue(v);
    };
    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title='差异化配置列表'/>
                    <Box sx={{ p: 5, pb: 3, pt: 0, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                            <Typography variant='body2' sx={{ mr: 2 }}>
                                Search:
                            </Typography>
                            <TextField
                                size='small'
                                value={value}
                                placeholder='Search Platform'
                                onChange={e => handleFilter(e.target.value)}
                            />
                        </Box>
                    </Box>
                    <DataGrid
                        autoHeight
                        rows={list.filter((v: any) => v.platform.toLowerCase().includes(value.toLowerCase()))}
                        columns={columns}
                        getRowId={getRowId}
                        disableSelectionOnClick
                        pagination={undefined}
                        disableColumnMenu
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default DiffList
