// ** Icon Imports
import Icon from '../../../components/icon'

// ** Custom Components Imports
import OptionsMenu from '../../../components/option-menu'

// ** Type Import
import {BossItem, Settings} from '../../../context/settingsContext'

interface Props {
    settings: Settings
    saveSettings: (values: Settings) => void
    bossList: BossItem[]
}

const TogglePlatform = ({ settings, saveSettings, bossList }: Props) => {
    // ** Vars
    const { layout, platform } = settings

    const getOptions = () => {
        const list = bossList.map((v: BossItem) => {
            return {
                text: v.label,
                menuItemProps: {
                    sx: { py: 2 },
                    selected: settings.platform === v.label,
                    onClick: () => {
                        console.log("saveSettings");
                        saveSettings({ ...settings, direction: 'ltr', platform: v.label })
                    }
                }
            }
        })
        return list
    }

    return (
        <OptionsMenu
            showLabel={true}
            icon={<Icon icon='fluent:arrow-sort-16-filled'/>}
            menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 80 } } }}
            iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
            options={getOptions()}
        />
    )
}

export default TogglePlatform
