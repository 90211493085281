import Tree from '../../../components/tree/Tree';
import TreeNode from '../../../components/tree/TreeNode';
import { styled } from '@mui/material/styles'
import UserIcon from '../../../components/icon/UserIcon'
import OptionsMenu from '../../../components/option-menu'
import {
    useEffect,
    useState,
    forwardRef,
    ReactElement,
    Ref,
    ReactNode,
    SyntheticEvent,
    useRef
} from "react";
import { useSettings } from "../../../hooks/useSettings"
import {fetch_menu_list, edit_menu, add_menu, delete_menu} from "../../../api/service/common";
import {useSnackbar} from "notistack";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { MenuTypes } from "../../types/Menu";
import Fade, { FadeProps } from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Icon from "../../../components/icon"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form';
import { useLoading } from '../../../useLoading';
import {makeUpToastParams} from "../../../utils/toast";

const Transition = forwardRef(function Transition(
    props: FadeProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Fade ref={ref} {...props} />
})

const StyledNode = styled('div')(({ theme }) => ({
    padding: '10px 15px',
    borderRadius: '8px',
    display: 'inline-block',
    background: '#fff',
    color: 'rgba(58, 53, 65, 0.87)',
    border: '2px solid #9155FD',
    boxShadow: '0px 2px 10px 0px rgba(58, 53, 65, 0.1)',
    '& .inner': {
        display: 'flex',
        alignItems: 'center',
        '.label': {
            marginLeft: '5px'
        }
    }
}))

// mdi:email-outline
const ExampleTree = () => {
    const defaultMenu: MenuTypes = {
        name: '',
        url: '',
        icon: '',
        sort_no: '',
        parent_id: 0
    }
    const { settings } = useSettings()
    const [menus, setMenus] = useState<any[]>([])
    const [modalTitle, setModalTitle] = useState<string>('')
    const [iconValue, setIconValue] = useState<string>('')
    const [error, setError] = useState('');
    const [modalShow, setModalShow] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();
    const toggleLoading: any = useLoading();
    const { handleSubmit, register, reset, formState: { errors }, setValue } = useForm();

    const onSubmit = async (data: any) => {
        try {
            if (iconErrorJudge(iconValue)) {
                return;
            }
            // 处理表单提交逻辑
            console.log(data);
            data.sort_no && (data.sort_no = parseInt(data.sort_no))
            iconValue && (data.icon = iconValue)
            // 有id是编辑，没id是新增
            if (data.id) {
                await edit_menu({...data, boss: settings.platform, type: '0'})
            } else {
                await add_menu({...data, boss: settings.platform, type: '0'})
            }
            // setModalShow(false);
            getMenuList();
            handleClose()
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'))
        }
    };

    useEffect(() => {
        settings.platform && getMenuList();
    }, [settings.platform])

    const handleClose = () => {
        reset(defaultMenu)
        setModalShow(false)
        setIconValue('')
    }

    const handleInputChange = (event: any) => {
        const value = event.target.value;
        // 进行校验逻辑
        // iconErrorJudge(value);
        setIconValue(value);
    };

    const iconErrorJudge = (val: string) => {
        if (!val) {
            setError('请输入菜单icon');
            return true;
        } else {
            setError('');
            return false;
        }
    }

    const getMenuList = async () => {
        try {
            toggleLoading(true);
            const menu_result: any = await fetch_menu_list(
                settings.platform
            );
            setMenus(menu_result);
            toggleLoading(false);
            console.log("menu_result", menu_result);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const addItem = (editable: boolean, id?: number | string) => {
        return {
            text: !editable ? '添加主菜单' : '添加子菜单',
            menuItemProps: {
                onClick: () => {
                    setModalTitle("添加子菜单")
                    reset(id ? {...defaultMenu, parent_id: id} : {...defaultMenu})
                    setModalShow(true);
                }
            }
        }
    }

    const editItem = (item: MenuTypes) => {
        return {
            text: '编辑菜单',
            menuItemProps: {
                onClick: () => {
                    setModalTitle("编辑菜单")
                    reset(item)
                    setIconValue(item.icon);
                    setModalShow(true);
                }
            }
        }
    }

    const delItem = (id?: string | number) => {
        return {
            text: '删除菜单',
            menuItemProps: {
                sx: { color: 'error.main' },
                onClick: () => {
                    try {
                        id && delete_menu(id, settings.platform).then(() => {
                            getMenuList()
                        })
                    } catch (e: any) {
                        enqueueSnackbar(e, makeUpToastParams('error'))
                    }
                }
            }
        }
    }
    const nodeItem = (item: MenuTypes, editable = true, bottom = false) => {
        return (
            <StyledNode>
                <div className="inner">
                    <UserIcon icon={item.icon ? item.icon : 'ri:menu-2-line'} />
                    <div className="label">{item.name}</div>
                    <OptionsMenu
                        iconButtonProps={{ size: 'small' }}
                        leftAlignMenu
                        options={(editable && !bottom) ? [
                            addItem(editable, item.id),
                            { divider: true },
                            editItem(item),
                            { divider: true },
                            delItem(item.id)
                        ] : (editable && bottom) ? [
                            editItem(item),
                            { divider: true },
                            delItem(item.id)
                        ] : [addItem(editable)]}
                    />
                </div>
            </StyledNode>
        )
    }

    return (
        <>
            { menus && menus.length > 0 && <Tree
                lineHeight={'2px'}
                lineColor={'#9155FD'}
                lineBorderRadius={'15px'}
                label={nodeItem({name: '菜单', icon: 'mdi:home-outline'} , false, false)}
            >
                {
                    menus.map((v: any) => (
                        <TreeNode key={v.id} label={nodeItem(v, true, false)}>
                            {
                                (v.children && v.children.length > 0) ? v.children.map((c: any) => (
                                    <TreeNode key={c.id} label={nodeItem(c, true, true)}/>
                                )) : undefined
                            }
                        </TreeNode>
                    ))
                }
            </Tree>}
            <Dialog
                fullWidth
                open={modalShow}
                maxWidth='md'
                scroll='body'
                onClose={handleClose}
                TransitionComponent={Transition}
                onBackdropClick={handleClose}
                PaperProps={{
                    style: {
                        width: '540px', // 设置对话框的宽度
                        maxWidth: '100%', // 可选，设置对话框的最大宽度
                    },
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent sx={{ pb: 8, px: { xs: 8, sm: 15 }, pt: { xs: 8, sm: 12.5 }, position: 'relative' }}>
                        <IconButton
                            size='small'
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
                        >
                            <Icon icon='mdi:close' />
                        </IconButton>
                        <Box sx={{ mb: 8, textAlign: 'center' }}>
                            <Typography variant='h5' sx={{ mb: 3 }}>
                                {modalTitle}
                            </Typography>
                        </Box>
                        <Grid container spacing={6}>
                            <Grid item sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label="菜单名称"
                                    {...register('name', { required: '菜单名称不能为空' })}
                                    placeholder='请输入菜单名称'
                                    error={!!errors.name}
                                    helperText={errors.name?.message as ReactNode}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    label='菜单url'
                                    {...register('url')}
                                    placeholder='请输入菜单url'
                                    error={!!errors.url}
                                    helperText={errors.url?.message as ReactNode}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    label='排序'
                                    {...register('sort_no')}
                                    placeholder='按数字从小到大排序'
                                    error={!!errors.sort_no}
                                    helperText={errors.sort_no?.message as ReactNode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label='菜单icon'
                                    value={iconValue}
                                    placeholder='请输入菜单icon'
                                    helperText={error}
                                    error={!!error}
                                    onChange={handleInputChange}
                                />
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography variant='caption' sx={{ mt: 2, color: 'text.secondary' }}>
                                        请从
                                        <Box
                                            href='https://icon-sets.iconify.design/'
                                            component={'a'}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            sx={{ textDecoration: 'none', color: 'primary.main' }}
                                        >
                                            icon选择地址
                                        </Box>
                                        中选入合适icon填入
                                    </Typography>
                                    <Typography variant='caption' sx={{ mt: 2, color: 'text.secondary' }}>
                                        <Box
                                            component={'a'}
                                            onClick={(e: SyntheticEvent) => {
                                                e.preventDefault()
                                                setIconValue('ri:menu-2-line')
                                            }}
                                            sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer' }}
                                        >
                                            使用默认icon
                                        </Box>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}>
                        <Button type="submit" variant='contained' sx={{ mr: 1 }}>
                            确定
                        </Button>
                        <Button variant='outlined' color='secondary' onClick={handleClose}>
                            取消
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
};

export default ExampleTree;
