export const BOSS_LIST = '/api/boss/list';
export const MENUS = '/api/menu/tree';
export const MENU = '/api/menu/create';
export const EDIT_MENU = '/api/menu/update';
export const DEL_MENU = (menu_id: string) => `/api/menu/${menu_id}`
export const USERS = '/api/users'
export const USER = 'api/user';
export const ROLES = '/api/roles'
export const ROLE = '/api/role';
export const TAG = '/api/tag';
export const TAGS = '/api/tags';
export const ROLE_MENU = '/api/menus/role';
export const EDIT_ROLE_MENU = '/api/role/menu';
export const ROLE_MENU_LIST = '/api/roles/menu'
export const ADD_ROLE = '/api/role/create'
export const DEL_ROLE = (role_id: string) => `/api/role/${role_id}`
export const ROLE_BIND_USERS = '/api/role/users'
export const ADD_USER = "/api/user/create"
export const DEL_USER = (user_id: string) => `/api/user/${user_id}`
export const USER_DETAIL = "/api/user/detail"
export const ROLE_USERS = "/api/roles/users"
export const EDIT_ROLE = "/api/role/update"
