import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Icon from "../components/icon"
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from "react";

type TopMsgItems = {
    platform: string,
    platformSwitch: boolean,
    onEdit: (type: 'add' | 'edit') => void,
    onSwitch: (status: boolean) => void;
}

const TopMsg = (props: TopMsgItems) => {
    return (
        <Card sx={{
            pt: 6, pb: 6, pr: 6
        }}>
            <Box
                sx={{
                    display: 'flex',
                    ml: { xs: 0, md: 6 },
                    alignItems: 'center',
                    flexWrap: ['wrap', 'nowrap'],
                    justifyContent: ['center', 'space-between']
                }}
            >
                <Box sx={{mr: 5, display: 'flex', alignItems: 'center'}}>
                    <Icon icon='tabler:category' fontSize={30}/>
                    <Typography variant='h5' sx={{ml: 2}}>
                        {props.platform}
                    </Typography>
                    <FormControlLabel
                        label={props.platformSwitch ? '平台开关开启' : '平台开关关闭'}
                        control={<Switch checked={props.platformSwitch} onChange={e => props.onSwitch(e.target.checked)}/>}
                        sx={{ '& .MuiTypography-root': { fontWeight: 500, fontSize: 14 }, ml: 2 }}
                    />
                </Box>
                <Button
                    variant='contained'
                    startIcon={<Icon icon='mdi:plus' fontSize={20} />}
                    onClick={() => props.onEdit('add')}
                >
                    新建分组
                </Button>
            </Box>
        </Card>
    )
}

export default TopMsg;
