// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit'

// ** Reducers
import user from './user'

export const store = configureStore({
  reducer: {
    user
  },
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
