import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {ReactNode} from "react";
import {ErrorType, RoleStepType} from "../../../types/Menu";

type RoleMsgItemType = {
    roleStepParams: RoleStepType,
    changeParams: (params: RoleStepType) => void,
    nameErrorText: string,
    clearErrorText: (type: ErrorType, value: string) => void
}

const RoleMsgItem = ({
    roleStepParams,
    changeParams,
    nameErrorText,
    clearErrorText
}: RoleMsgItemType) => {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
                <TextField
                    value={roleStepParams.name}
                    onChange={(e: any) => (
                        changeParams({
                            ...roleStepParams,
                            name: e.target.value
                        })
                    )}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    fullWidth
                    label='角色名称'
                    placeholder='请输入'
                    onBlur={(e: any) => clearErrorText('name', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <TextField
                    fullWidth
                    rows={4}
                    value={roleStepParams.description}
                    onChange={(e: any) => {
                        changeParams({
                            ...roleStepParams,
                            description: e.target.value
                        })
                    }}
                    multiline
                    label='备注'
                    placeholder='请输入'
                />
            </Grid>
        </Grid>
    )

}

export default RoleMsgItem
