import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {styled} from "@mui/material/styles";
import MuiTimeline, {TimelineProps} from "@mui/lab/Timeline";
import {HistoryTypes} from "./types/Diff";
import { formatTimestamp } from "../utils/common";
import Collapse from '@mui/material/Collapse'
import Icon from "../components/icon"
import IconButton from '@mui/material/IconButton'

const Timeline = styled(MuiTimeline)<TimelineProps>(({ theme }) => ({
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(0.75),
    '& .MuiTimelineItem-root': {
        '&:before': {
            display: 'none'
        },
        '&:last-child': {
            minHeight: 60
        }
    }
}))

interface Props {
    editHistory: HistoryTypes[],
    collapsed: boolean,
    setCollapsed: (collapsed: boolean) => void
}

const EditRecord = ({editHistory, collapsed, setCollapsed} : Props) => {
    const dotColor: any[] = ['grey', 'primary', 'secondary', 'error', 'info', 'success', 'warning'];
    return (
        <Card sx={{
            mt: 6
        }}>
            <CardHeader title='修改记录' action={
                <IconButton
                    size='small'
                    aria-label='collapse'
                    sx={{ color: 'text.secondary' }}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Icon fontSize={20} icon={!collapsed ? 'mdi:chevron-down' : 'mdi:chevron-up'} />
                </IconButton>
            }/>
            <Collapse in={collapsed}>
                <CardContent>
                    <Timeline>
                        {
                            editHistory.map((v,index) => (
                                <TimelineItem key={index}>
                                    <TimelineSeparator>
                                        <TimelineDot color={dotColor[Math.floor(Math.random() * 7)]} />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Box
                                            sx={{
                                                mb: 2,
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography variant='body2' sx={{ mr: 2, fontWeight: 600, color: 'text.primary' }}>
                                                {v.title}
                                            </Typography>
                                            <Typography variant='caption'>{formatTimestamp(v.timestamp, 'YYYY-MM-DD HH:mm')}</Typography>
                                        </Box>
                                        <Typography variant='body2'>
                                        <span style={{whiteSpace: "pre-wrap"}}>{
                                            JSON.stringify(v.description, null, 2)
                                        }</span>
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))
                        }
                    </Timeline>
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default EditRecord;
