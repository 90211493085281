export const makeUpToastParams = (
    type: 'default' | 'success' | 'error' | 'warning' | 'info' = 'success',
    duration: number = 2000,
    vertical: 'top' | 'bottom' = 'top',
    horizontal: 'left' | 'center' | 'right' = 'center'
) => {
    return {
        variant: type,
        anchorOrigin: {
            vertical: vertical,
            horizontal: horizontal,
        },
        autoHideDuration: duration,
        preventDuplicate: true
    }
}
