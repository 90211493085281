import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Icon from "../../../../components/icon"
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TableBody from '@mui/material/TableBody'
import { useState } from "react"
import {EditMenuTypes, ErrorType, RoleStepType} from "../../../types/Menu";
import React from 'react';
import { getTreeChildIdList } from "../../../../utils/common";

type RoleMenuItemProps = {
    menuList: EditMenuTypes[],
    roleStepParams: RoleStepType,
    changeParams: (params: RoleStepType) => void,
    clearErrorText: (type: ErrorType, value: string) => void
}

const RoleMenuItem = ({
    menuList,
    roleStepParams,
    changeParams,
    clearErrorText
}: RoleMenuItemProps) => {

    const handleSelectAllCheckbox = () => {
        const chosenLength = getTreeChildIdList(menuList);
        if (chosenLength.length === roleStepParams.menu_ids.length) {
            changeParams({
                ...roleStepParams,
                menu_ids: []
            })
        } else {
            changeParams({
                ...roleStepParams,
                menu_ids: getTreeChildIdList(menuList)
            })
        }
    }

    const getChildLength = (menu: EditMenuTypes) => {
        let length = 0;
        if (menu.children) {
            length += menu.children.length; // 累加当前节点的子节点数量
            // 遍历每个子节点，如果子节点还有子节点，则继续递归计算长度
            menu.children.forEach((child) => {
                if (child.children) {
                    length += getChildLength(child);
                }
            });
        }
        return length;
    }

    const getSubMenuLength = () => {
        return menuList.reduce((length, menu) => {
            return length + getChildLength(menu);
        }, 0)
    }

    const togglePermission = (id: number, state: boolean) => {
        console.log("newValue", state, id);
        const temp_menu_ids = [...roleStepParams.menu_ids];
        if (state) {
            temp_menu_ids.push(id)
        } else {
            const index = temp_menu_ids.indexOf(id);
            temp_menu_ids.splice(index, 1);
        }
        console.log("temp_menu_ids", temp_menu_ids);
        changeParams({
            ...roleStepParams,
            menu_ids: temp_menu_ids
        })
    }

    return <TableContainer>
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ pl: '0 !important' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                                '& svg': { ml: 1, cursor: 'pointer' }
                            }}
                        >
                            菜单
                        </Box>
                    </TableCell>
                    <TableCell sx={{ pl: '0 !important' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                                padding: '6px 16px',
                                '& svg': { ml: 1, cursor: 'pointer' }
                            }}
                        >
                            二级菜单
                        </Box>
                    </TableCell>
                    <TableCell>
                        <FormControlLabel
                            label='全选'
                            sx={{ '& .MuiTypography-root': {
                                textTransform: 'capitalize',
                                fontSize: '14px'
                            } }}
                            control={
                                <Checkbox
                                    size='small'
                                    onChange={handleSelectAllCheckbox}
                                    checked={getSubMenuLength() === roleStepParams.menu_ids.length}
                                />
                            }
                        />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {menuList.map((menu: EditMenuTypes) => {
                    const name = menu.name
                    return (
                        <React.Fragment key={menu.id}>
                            <TableRow key={menu.id} sx={{ '& .MuiTableCell-root:first-of-type': { pl: '0 !important' } }}>
                                <TableCell
                                    sx={{
                                        fontWeight: 600,
                                        whiteSpace: 'nowrap',
                                        color: theme => `${theme.palette.text.primary} !important`
                                    }}
                                    rowSpan={menu.children ? menu.children.length : 1}
                                >
                                    {name}
                                </TableCell>
                                {menu.children && <>
                                    <TableCell>{menu.children && menu.children[0].name}</TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            label=''
                                            control={
                                                <Checkbox
                                                    size='small'
                                                    id={menu.children[0].id.toString()}
                                                    onChange={
                                                        (event, newValue: any) =>
                                                            menu.children && togglePermission(menu.children[0].id, newValue)
                                                    }
                                                    checked={roleStepParams.menu_ids.includes(menu.children[0].id)}
                                                />
                                            }
                                        />
                                    </TableCell>
                                </>}
                            </TableRow>
                            {
                                menu.children && menu.children.slice(1).map((v: any) => (
                                    <TableRow key={v.id}>
                                        <TableCell style={{paddingLeft: '16px'}}>{v.name}</TableCell>
                                        <TableCell>
                                            <FormControlLabel
                                                label=''
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        id={v.id.toString()}
                                                        onChange={
                                                            (
                                                                event,
                                                                state: boolean
                                                            ) => togglePermission(v.id, state)
                                                        }
                                                        checked={roleStepParams.menu_ids.includes(v.id)}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </React.Fragment>
                    )
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export default RoleMenuItem;
