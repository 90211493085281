import Grid from '@mui/material/Grid'
import PlanBaseMsg from "./PlanBaseMsg";
import EditRecord from "./EditRecord";
import PlanDiffMsg from "./PlanDiffMsg"
import {PlanInfoTypes, HistoryTypes, Plan} from "./types/Diff";
interface Props {
    info: PlanInfoTypes,
    editPlanInfo: PlanInfoTypes,
    onSwitch: (status: boolean) => void,
    onEdit: (type: 'add' | 'edit') => void,
    status: boolean,
    onClose: () => void,
    onSave: () => void,
    editHistory: HistoryTypes[],
    editStatus: boolean,
    modalTitle: string,
    firstTab: string,
    secondTab: string,
    jsonContent: string,
    afStatus: any,
    configContent: any,
    planList: Plan[],
    campaignInfo: any,
    mediaSource: any,
    formChange: (info: PlanInfoTypes) => void,
    setFirstTab: (tab: string) => void,
    setSecondTab: (tab: string) => void,
    onJsonChange: (json: string) => void,
    onConfigSave: () => void,
    onDelete: () => void,
    platform: string,
    plan: string,
    collapsed: boolean,
    setCollapsed: (collapsed: boolean) => void,
    onSaveConfigItem: (text: string) => void
}
const DiffItem = (
    {
        info,
        onSwitch,
        status,
        editHistory,
        onEdit,
        onClose,
        editStatus,
        modalTitle,
        firstTab,
        secondTab,
        configContent,
        afStatus,
        formChange,
        campaignInfo,
        mediaSource,
        editPlanInfo,
        planList,
        onSave,
        setFirstTab,
        setSecondTab,
        jsonContent,
        onConfigSave,
        onJsonChange,
        platform,
        plan,
        onDelete,
        collapsed,
        setCollapsed,
        onSaveConfigItem
    }: Props
) => {
    return (
        <Grid container spacing={6} sx={{height: "100%"}}>
            <Grid item xs={12} md={5} lg={4}>
                <PlanBaseMsg
                    info={info}
                    status={status}
                    onSwitch={onSwitch}
                    onEdit={onEdit}
                    onClose={onClose}
                    editStatus={editStatus}
                    modalTitle={modalTitle}
                    afStatus={afStatus}
                    formChange={formChange}
                    campaignInfo={campaignInfo}
                    mediaSource={mediaSource}
                    editPlanInfo={editPlanInfo}
                    planList={planList}
                    onSave={onSave}
                    onDelete={onDelete}
                />
                <EditRecord editHistory={editHistory} collapsed={collapsed} setCollapsed={setCollapsed}/>
            </Grid>
            <Grid item xs={12} md={7} lg={8} sx={{pt: '0!important'}}>
                <PlanDiffMsg
                    onSaveConfigItem={onSaveConfigItem}
                    configContent={configContent}
                    firstTab={firstTab}
                    secondTab={secondTab}
                    setFirstTab={setFirstTab}
                    setSecondTab={setSecondTab}
                    jsonContent={jsonContent}
                    onConfigSave={onConfigSave}
                    onJsonChange={onJsonChange}
                    platform={platform}
                    plan={plan}
                    planList={planList}
                    info={info}
                />
            </Grid>
        </Grid>
    )
}

export default DiffItem;
