import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TableHead from '@mui/material/TableHead'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Checkbox from '@mui/material/Checkbox'
import React, {useEffect, useState} from "react";
import { useSettings } from "../../../hooks/useSettings"
import {useSnackbar} from "notistack";
import CardActions from '@mui/material/CardActions'
import TableCell from '@mui/material/TableCell'
import {
    fetch_menu_list,
    role_bind_users,
    fetch_role_menu_list,
    set_role_menus,
    del_role,
    add_role,
    fetch_role_users,
    edit_role
} from "../../../api/service/common"
import { useLoading } from '../../../useLoading';
import Icon from "../../../components/icon";
import "./RoleManage.scss"
import PageHeader from "../../../components/page-header";
import Grid from "@mui/material/Grid";
import RoleCards from "./RoleCards";
import {
    FirstMenuProps,
    SecondMenuProps,
    EditMenuTypes,
    RoleMenusType,
    ServiceRoleMenuType,
    ServiceRoleType,
    UserRoleTypes,
    RoleUserType,
    UserType
} from "../../types/Menu";
import FormControlLabel from "@mui/material/FormControlLabel";
import {deepClone} from "../../../utils/common";
import {makeUpToastParams} from "../../../utils/toast";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {fetchData} from "../../../store/user";

// mdi:email-outline
const ExampleTree = () => {
    const { settings } = useSettings()
    const [firstMenus, setFirstMenus] = useState<FirstMenuProps[]>([])
    const [menus, setMenus] = useState<EditMenuTypes[]>([])
    const [secondMenus, setSecondMenus] = useState<SecondMenuProps[]>([])
    const [roles, setRoles] = useState<RoleUserType[]>([])
    const [roleMenus, setRoleMenus] = useState<RoleMenusType[]>([])
    const [tempRoleMenus, setTempRoleMenus] = useState<RoleMenusType[]>([])
    const { enqueueSnackbar } = useSnackbar();
    const toggleLoading: any = useLoading();
    const dispatch = useDispatch<AppDispatch>()
    const store: any = useSelector((state: RootState) => state.user)
    const userList: UserType[] = store.users;

    useEffect(() => {
        dispatch(
            fetchData()
        )
    }, [dispatch])

    useEffect(() => {
        settings.platform && getBaseMsg();
    }, [settings.platform])

    useEffect(() => {
        const tableContainer = document.getElementById('tableContainer');
        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        console.log("change")
        setTempRoleMenus(deepClone(roleMenus));
    }, [roleMenus])

    const handleScroll = (event: any) => {
        const tableContainer = event.target;
        const shadowColumn = deepClone(tableContainer.querySelectorAll('.cell-fixed'));
        if (tableContainer.scrollLeft > 0) {
            shadowColumn.map((v: any) => v.classList.add('shadow'));
        } else {
            shadowColumn.map((v: any) => v.classList.remove('shadow'));
        }
    };

    const saveRoleMenus = async () => {
        try {
            toggleLoading(true);
            const service_params: ServiceRoleMenuType[] = tempRoleMenus.map((v) => {
                return {
                    role_id: v.role_id,
                    menu_ids: v.menu_ids.filter((m) => !firstMenus.find((f) => f.id === m))
                }
            });
            await set_role_menus(service_params, settings.platform);
            await getRoleMenuList();
            toggleLoading(false);
        } catch (e: any) {
            // return Promise.reject(e)
            enqueueSnackbar(e, makeUpToastParams('error'))
        }
    }

    const resetRoleMenus = async () => {
        setTempRoleMenus(deepClone(roleMenus));
    }

    const getRoleList = async () => {
        try {
            const result: any = await fetch_role_users();
            setRoles(result);
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const getRoleMenuList = async () => {
        try {
            const result: any = await fetch_role_menu_list(settings.platform);
            setRoleMenus(result)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const deleteRole = async (role_id: number) => {
        try {
            await del_role(role_id.toString(), settings.platform);
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'))
        }
    }

    const editRole = async (params: ServiceRoleType): Promise<number> => {
        try {
            return params.id ? await edit_role(params) : await add_role(params)
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'))
            return -1
        }
    }

    const roleBindUser = async (user_roles: UserRoleTypes[]) => {
        try {
            await role_bind_users(user_roles, settings.platform)
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'))
        }
    }

    const roleBindMenu = async (role_menus: ServiceRoleMenuType[]) => {
        try {
            await set_role_menus(role_menus, settings.platform)
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'))
        }
    }

    const getMenuList = async () => {
        try {
            const menus: any = await fetch_menu_list(
                settings.platform
            );
            const first_menus: FirstMenuProps[] = [];
            const second_menus: SecondMenuProps[] = [];
            menus.forEach((v: any, index: number) => {
                first_menus.push({
                    id: v.id,
                    name: v.name,
                    len: v.children.length,
                    border: index !== menus.length - 1
                })
                v.children.forEach((c: any, c_index: number) => {
                    second_menus.push({
                        id: c.id,
                        name: c.name,
                        border: index !== menus.length - 1 && c_index === v.children.length - 1
                    })
                })
            })
            setMenus(menus);
            setFirstMenus(first_menus)
            setSecondMenus(second_menus)
        } catch (e) {
            return Promise.reject(e);
        }
    }

    const getBaseMsg = async () => {
        toggleLoading(true);
        Promise.all([getRoleList(), getMenuList(), getRoleMenuList()]).then(
            () => {
                toggleLoading(false);
                // 获取到数据之后
            }
        ).catch((e: any) => {
            enqueueSnackbar(e, makeUpToastParams('error'))
            toggleLoading(false);
        });
    }

    const changeRoleMenus = (role_id: number, menu_id: number, role_index: number) => {
        console.log("role_id", role_id, menu_id, roleMenus)
        let role_menus = tempRoleMenus.find((v: RoleMenusType) => v.role_id === role_id)!;
        let menu_ids = role_menus ? role_menus.menu_ids : [];
        const index = menu_ids.indexOf(menu_id);
        if (index !== -1) {
            menu_ids.splice(index, 1)
        } else {
            menu_ids.push(menu_id)
        }
        role_menus = {
            ...role_menus,
            menu_ids
        }
        const newArray = tempRoleMenus.map((v) => {
            if (v.role_id === role_id) {
                return { ...v, role_menus }; // 使用扩展运算符替换属性值
            } else {
                return v; // 保持原对象
            }
        });
        console.log("roleMenus", newArray);
        setTempRoleMenus(newArray);
    }

    return (
        <Grid container spacing={6}>
            <PageHeader
                title={<Typography variant='h6'>角色列表</Typography>}
            />
            <Grid item xs={12} sx={{ mb: 4 }}>
                <RoleCards
                    roleList={roles}
                    menuList={menus}
                    userList={userList}
                    delete_role={deleteRole}
                    edit_role={editRole}
                    bind_user={roleBindUser}
                    bind_menu={roleBindMenu}
                    role_bind_user={roleBindUser}
                    update_user_list={getRoleList}
                />
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title='角色菜单配置' />

                    <Divider sx={{ m: '0 !important' }} />

                    <CardContent>
                        <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary' }}>
                            请在列表中勾选希望角色拥有的菜单权限，并点击保存按钮更新你的设置
                        </Typography>
                    </CardContent>

                    <Divider sx={{ m: '0 !important' }} />

                    <TableContainer id="tableContainer" style={{ overflowX: 'auto' }}>
                        <Table sx={{ minWidth: 500, tableLayout: 'auto' }} >
                            <TableHead
                                sx={{ backgroundColor: theme => (theme.palette.mode === 'light' ? 'grey.50' : 'background.default') }}
                            >
                                <TableRow>
                                    <TableCell
                                        className="cell-fixed"
                                        rowSpan={2}
                                        align="center"
                                    >
                                        角色 \ 菜单
                                    </TableCell>
                                    {firstMenus.map((v: any) => (
                                        <TableCell
                                            key={v.id}
                                            colSpan={v.len}
                                            className={v.border ? 'cell-item border' : 'cell-item'}
                                            align="center"
                                        >
                                            {v.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    {secondMenus.map((v: any) => (
                                        <TableCell
                                            className={v.border ? 'cell-item border' : 'cell-item'}
                                            key={v.id}
                                            style={{ whiteSpace: 'nowrap' }}
                                            align='center'>
                                            {v.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    tempRoleMenus.map((v) => (
                                        <TableRow key={v.role_id} hover>
                                            <TableCell
                                                className="cell-fixed"
                                                align="center"
                                            >
                                                <Typography variant='body2' sx={{ color: 'text.primary' }}>
                                                    {v.role_name}
                                                </Typography>
                                            </TableCell>
                                            {secondMenus.map((m: any, index) => (
                                                <TableCell
                                                    key={m.id}
                                                    align='center'
                                                    sx={{ pt: '0 !important', pb: '0 !important' }}
                                                    className={m.border ? 'cell-item border' : 'cell-item'}
                                                >
                                                    {/*<Checkbox checked={v.menu_ids.includes(m.id)}/>*/}
                                                    <FormControlLabel
                                                        label=''
                                                        control={
                                                            <Checkbox
                                                                onChange={() => changeRoleMenus(v.role_id, m.id, index)}
                                                                checked={v.menu_ids.includes(m.id)}
                                                            />
                                                        }
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <CardActions>
                        <Button
                            startIcon={
                                <Icon icon='carbon:save' style={{fontSize: 18}}/>
                            }
                            variant='contained'
                            sx={{ mr: 2 }}
                            onClick={saveRoleMenus}
                        >
                            保存
                        </Button>
                        <Button
                            startIcon={
                                <Icon icon='grommet-icons:power-reset' style={{fontSize: 18}}/>
                            }
                            color='secondary'
                            variant='outlined'
                            onClick={resetRoleMenus}
                        >
                            重置
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
};

export default ExampleTree;
