import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import DialogActions from '@mui/material/DialogActions'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from "../components/icon"
import React, {useEffect, useState} from 'react'
import CardHeader from "@mui/material/CardHeader";
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import {Plan, PlanInfoTypes} from "./types/Diff";
import {
    getCountry,
    getMediaFromCampaignId,
    mergeArr,
    getAfStatusFromMedia,
    getCountryName
} from "../utils/common";

interface Props {
    info: PlanInfoTypes,
    editPlanInfo: PlanInfoTypes,
    onSwitch: (status: boolean) => void,
    onSave: () => void,
    onDelete: () => void,
    status: boolean,
    onEdit: (type: 'add' | 'edit') => void,
    onClose: () => void,
    editStatus: boolean,
    modalTitle: string,
    afStatus: any,
    planList: Plan[],
    campaignInfo: any,
    mediaSource: any,
    formChange: (info: PlanInfoTypes) => void
}

const PlanBaseMsg = (
    {
        info,
        editPlanInfo,
        onSwitch,
        status,
        onEdit,
        editStatus,
        onClose,
        modalTitle,
        afStatus,
        formChange,
        campaignInfo,
        mediaSource,
        planList,
        onSave,
        onDelete
    }: Props
) => {
    const handleChange = (checked: boolean) => {
        onSwitch(checked);
    }

    const [countryList, setCountryList] = useState<any[]>([])

    const vipList = [{
        value: '0',
        label: '否'
    }, {
        value: '1',
        label: '是'
    }]

    useEffect(() => {
        setCountryList(getCountry());
    }, [])

    return (
        info &&
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="test">
              <CardHeader sx={{
                  '& .MuiCardHeader-content': {
                      flexShrink: 0
                  },
                  '& .MuiCardHeader-action': {
                      flex: {
                          md: 'auto',
                          lg: 'auto',
                          xl: 'none'
                      }
                  },
                  alignItems: {
                      md: 'flex-start',
                      lg: 'flex-start',
                      xl: 'center'
                  }
              }}
                title={
                    <Box className="test3" sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <Typography variant='h6'>
                            分组信息
                        </Typography>
                        <FormControlLabel
                            label={status ? '分组开关开启' : "分组开关关闭"}
                            control={
                                <Switch
                                    checked={status}
                                    onChange={e => handleChange(e.target.checked)}
                                />
                            }
                            sx={{'& .MuiTypography-root': {fontWeight: 500, fontSize: 14}, ml: 2}}
                        />
                    </Box>
                }
                action={
                    <Box className="test" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: {
                            md: 'row-reverse',
                            lg: 'row-reverse',
                            xl: 'row'
                        }
                    }}>
                        <Button
                            startIcon={<Icon icon='carbon:edit' style={{fontSize: 16}}/>}
                            variant='contained'
                            sx={{
                                mr: {
                                    xs: 2,
                                    sm: 2,
                                    md: 0,
                                    lg: 0,
                                    xl: 2
                                }
                            }}
                            onClick={() => onEdit('edit')}
                        >
                            编辑
                        </Button>
                        <Button
                            startIcon={<Icon icon='carbon:trash-can' style={{fontSize: 16}}/>}
                            variant='contained'
                            sx={{
                                mt: {md: 2, lg: 2, xl: 0}
                            }}
                            onClick={onDelete}
                        >
                            删除
                        </Button>
                    </Box>
                }
              />
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={12}>
                    <TableContainer>
                      <Table size='small' sx={{width: '100%'}}>
                        <TableBody
                          sx={{
                              '& .MuiTableCell-root': {
                                  border: 0,
                                  pt: 2,
                                  pb: 2.5,
                                  pl: '0 !important',
                                  pr: '0 !important',
                                  '&:first-of-type': {
                                      width: 148
                                  }
                              }
                          }}
                        >
                            {info.af_status && <TableRow>
                              <TableCell>
                                <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                  af_status:
                                </Typography>
                              </TableCell>
                              <TableCell>{info.af_status}</TableCell>
                            </TableRow>}
                            {info.media_source && info.media_source.length > 0 && <TableRow>
                              <TableCell>
                                <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                  media_source:
                                </Typography>
                              </TableCell>
                              <TableCell>{info.media_source && info.media_source.join(', ')}</TableCell>
                            </TableRow>}
                            {info.campaign_id && info.campaign_id.length > 0 && <TableRow>
                              <TableCell>
                                <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                  campaign_id:
                                </Typography>
                              </TableCell>
                              <TableCell>{info.campaign_id && info.campaign_id.join(", ")}</TableCell>
                            </TableRow>}
                            {info.country && info.country.length > 0 && <TableRow>
                            <TableCell>
                              <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                country{info.invert ? "(反选)" : ""}:
                              </Typography>
                            </TableCell>
                            <TableCell>{getCountryName(info.country)}</TableCell>
                          </TableRow>}
                            {
                                info.vip && <TableRow>
                                  <TableCell>
                                    <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                      VIP:
                                    </Typography>
                                  </TableCell>
                                  <TableCell>{info.vip === '1' ? '是' : '否'}</TableCell>
                                </TableRow>
                            }
                          <TableRow>
                            <TableCell>
                              <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                依赖分组:
                              </Typography>
                            </TableCell>
                            <TableCell>{info.parent_plan}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography variant='subtitle2' sx={{color: 'text.primary'}}>
                                分组别名:
                              </Typography>
                            </TableCell>
                            <TableCell>{info.alias ? info.alias : '无'}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>

              <Dialog
                open={editStatus}
                onClose={() => onEdit('edit')}
                aria-labelledby='user-view-edit'
                sx={{'& .MuiPaper-root': {width: '100%', maxWidth: 650, p: [2, 10]}}}
                aria-describedby='user-view-edit-description'
              >
                <DialogTitle id='user-view-edit' sx={{textAlign: 'center', fontSize: '1.5rem !important'}}>
                    {modalTitle}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText variant='body2' id='user-view-edit-description' sx={{textAlign: 'center', mb: 7}}>
                  </DialogContentText>
                  <form>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            options={Object.keys(afStatus)}
                            value={editPlanInfo.af_status}
                            onChange={(event, newValue: any) => {
                                formChange({
                                    ...editPlanInfo,
                                    af_status: newValue
                                })
                            }}
                            renderInput={(params) => <TextField {...params} label="af_status" variant="outlined"/>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            {/*<InputLabel id='media-label'>media_source</InputLabel>*/}
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={mediaSource}
                            value={mediaSource.filter((v: any) => editPlanInfo.media_source.includes(v.value))}
                            getOptionLabel={(option: any) => option.label}
                            renderInput={params => <TextField {...params} label='media_source'/>}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox checked={selected} sx={{mr: 2}}/>
                                    {option.label}
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                const new_af_status = newValue.length > 0 ? newValue[newValue.length - 1].af_status : "";
                                const new_media = newValue.map((v) => v.value);
                                formChange({
                                    ...editPlanInfo,
                                    media_source: new_media,
                                    af_status: new_af_status ? new_af_status : editPlanInfo.af_status
                                })
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={campaignInfo}
                            value={campaignInfo.filter((v: any) => editPlanInfo.campaign_id.includes(v.value))}
                            getOptionLabel={(option: any) => option.label}
                            renderInput={params => <TextField {...params} label='campaign_id'/>}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox checked={selected} sx={{mr: 2}}/>
                                    {option.label}
                                </li>
                            )}
                            onChange={(event: any, newValue) => {
                                const new_campaign = newValue.map((v) => v.value);
                                const new_item_id = newValue.length > 0 ? newValue[newValue.length - 1].value : "";
                                const new_media = getMediaFromCampaignId([new_item_id], campaignInfo)
                                const new_af_status = getAfStatusFromMedia(
                                    new_media.length > 0 ? new_media[new_media.length - 1] : "",
                                    mediaSource
                                );
                                formChange({
                                    ...editPlanInfo,
                                    campaign_id: new_campaign,
                                    media_source: mergeArr(editPlanInfo.media_source, new_media),
                                    af_status: new_af_status ? new_af_status : editPlanInfo.af_status
                                })
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={
                              <Switch checked={editPlanInfo.invert} onChange={(e: any) => {
                                  formChange({
                                      ...editPlanInfo,
                                      invert: e.target.checked
                                  })
                              }}/>
                          }
                          sx={{'& .MuiTypography-root': {fontSize: 14}}}
                          label="启用反选"
                        />
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={countryList}
                            value={countryList.filter((v: any) => editPlanInfo.country.includes(v.id))}
                            getOptionLabel={(option: any) => option.text}
                            renderInput={params => <TextField {...params} label='country'/>}
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox checked={selected} sx={{mr: 2}}/>
                                    {option.text}
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                formChange({
                                    ...editPlanInfo,
                                    country: newValue.map((v: any) => v.id)
                                })
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Autocomplete
                            options={vipList}
                            value={vipList.find((v: any) => editPlanInfo.vip === v.value)}
                            onChange={(event, newValue: any) => {
                                formChange({
                                    ...editPlanInfo,
                                    vip: newValue ? newValue.value : ''
                                })
                            }}
                            getOptionLabel={(v: any) => v.label}
                            renderInput={(params) => <TextField {...params} label="VIP" variant="outlined"/>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Autocomplete
                            options={modalTitle === '添加分组' ? [{name: 'base'}, ...planList] : [{name: 'base'}, ...planList].filter((v) => v.name !== editPlanInfo.plan_name)}
                            value={[{name: 'base'}, ...planList].find((v: Plan) => editPlanInfo.parent_plan === v.name)}
                            onChange={(event, newValue: any) => {
                                formChange({
                                    ...editPlanInfo,
                                    parent_plan: newValue ? newValue.name : ''
                                })
                            }}
                            getOptionLabel={(plan: Plan) => plan.alias ? plan.name + "-" + plan.alias : plan.name}
                            renderInput={(params) => <TextField {...params} label="依赖分组" variant="outlined"/>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='分组名称'
                          value={editPlanInfo.plan_name}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label='分组别名'
                          value={editPlanInfo.alias}
                          onChange={(e: any) => (
                              formChange({
                                  ...editPlanInfo,
                                  alias: e.target.value
                              })
                          )}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'center'}}>
                  <Button
                    startIcon={
                        <Icon icon='carbon:save' style={{fontSize: 18}}/>
                    }
                    variant='contained'
                    sx={{mr: 1}}
                    onClick={onSave}
                  >
                    保存
                  </Button>
                  <Button
                    startIcon={
                        <Icon icon='material-symbols:cancel' style={{fontSize: 18}}/>
                    }
                    variant='outlined'
                    color='secondary'
                    onClick={onClose}
                  >
                    取消
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </Grid>
        </Grid>
    )
}

export default PlanBaseMsg
