import Drawer from "./Drawer";
import {ReactNode, useState, useRef} from 'react'
import {Settings} from "../../context/settingsContext"
import VerticalNavHeader from "./components/VerticalNavHeader"
import Box from '@mui/material/Box'
import PerfectScrollbar from 'react-perfect-scrollbar'
import themeConfig from '../../configs/themeConfig'
import List from '@mui/material/List'
import VerticalNavItems from "./components/VerticalNavItems"
import {VerticalLayoutProps} from "../../layouts/types";
interface NavigationTypes {
    hidden: boolean
    navWidth: number
    navVisible: boolean
    setNavVisible: (value: boolean) => void
    // children: ReactNode
    collapsedNavWidth: number
    toggleNavVisibility: () => void
    settings: Settings
    saveSettings: (values: Settings) => void
    verticalLayoutProps: VerticalLayoutProps
}

const Navigation = (props: NavigationTypes) => {
    const { hidden, settings, verticalLayoutProps } = props;
    const [navHover, setNavHover] = useState<boolean>(false)
    const [groupActive, setGroupActive] = useState<string[]>([])
    const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([])

    const ScrollWrapper = hidden ? Box : PerfectScrollbar

    const { navCollapsed } = settings;

    const { beforeVerticalNavMenuContentPosition } = themeConfig

    const shadowRef = useRef(null)

    // ** Scroll Menu
    const scrollMenu = (container: any) => {
        if (beforeVerticalNavMenuContentPosition === 'static') {
            container = hidden ? container.target : container
            if (shadowRef && container.scrollTop > 0) {
                // @ts-ignore
                if (!shadowRef.current.classList.contains('scrolled')) {
                    // @ts-ignore
                    shadowRef.current.classList.add('scrolled')
                }
            } else {
                // @ts-ignore
                shadowRef.current.classList.remove('scrolled')
            }
        }
    }

    const handleInfiniteScroll = (ref: HTMLElement) => {
        if (ref) {
            // @ts-ignore
            ref._getBoundingClientRect = ref.getBoundingClientRect

            ref.getBoundingClientRect = () => {
                // @ts-ignore
                const original = ref._getBoundingClientRect()

                return { ...original, height: Math.floor(original.height) }
            }
        }
    }

    return (
        <Drawer {...props} navHover={navHover} setNavHover={setNavHover}>
            <VerticalNavHeader {...props} navHover={navHover} />
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                {/* @ts-ignore */}
                <ScrollWrapper
                    {...(hidden
                        ? {
                            onScroll: (container: any) => scrollMenu(container),
                            sx: { height: '100%', overflowY: 'auto', overflowX: 'hidden' }
                        }
                        : {
                            options: { wheelPropagation: false },
                            onScrollY: (container: any) => scrollMenu(container),
                            containerRef: (ref: any) => handleInfiniteScroll(ref)
                        })}
                >
                    <List
                        className='nav-items'
                        sx={{
                            pt: 0,
                            transition: 'padding .25s ease',
                            '& > :first-of-type': { mt: '0' },
                            pr: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25
                        }}
                    >
                        <VerticalNavItems
                            navHover={navHover}
                            groupActive={groupActive}
                            setGroupActive={setGroupActive}
                            currentActiveGroup={currentActiveGroup}
                            setCurrentActiveGroup={setCurrentActiveGroup}
                            verticalNavItems={verticalLayoutProps.navMenu.navItems}
                            {...props}
                        />
                    </List>
                </ScrollWrapper>
            </Box>
        </Drawer>
    )
}

export default Navigation;
