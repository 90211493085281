import { NavGroup, NavLink } from "../../../layouts/types";

export const handleURLQueries = (router: any, path: string | undefined): boolean => {
    const query: any = new URLSearchParams(router.search);
    if (Object.keys(query).length && path) {
        const query: any = new URLSearchParams(router.search);
        const arr = Object.keys(query)
        return router.pathname.includes(path) && router.pathname.includes(query[arr[0]] as string) && path !== '/'
    }

    return false
}

export const hasActiveChild = (item: NavGroup, currentURL: string): boolean => {
    const { children } = item

    if (!children) {
        return false
    }

    for (const child of children) {
        if ((child as NavGroup).children) {
            if (hasActiveChild(child, currentURL)) {
                return true
            }
        }
        const childPath = (child as NavLink).url

        // Check if the child has a link and is active
        if (
            child &&
            childPath &&
            currentURL &&
            (childPath === currentURL || (currentURL.includes(childPath) && childPath !== '/'))
        ) {
            return true
        }
    }

    return false
}

export const removeChildren = (children: NavLink[], openGroup: string[], currentActiveGroup: string[]) => {
    children.forEach((child: NavLink) => {
        if (!currentActiveGroup.includes(child.name)) {
            const index = openGroup.indexOf(child.name)
            if (index > -1) openGroup.splice(index, 1)

            // @ts-ignore
            if (child.children) removeChildren(child.children, openGroup, currentActiveGroup)
        }
    })
}
