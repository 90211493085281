import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import {UserType, RoleStepType, ErrorType} from "../../../types/Menu";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

type RoleUserItemProps = {
    roleStepParams: RoleStepType,
    changeParams: (params: RoleStepType) => void,
    clearErrorText: (type: ErrorType, value: string) => void,
    userList: UserType[]
}

const RoleUserItem = ({
    roleStepParams,
    clearErrorText,
    changeParams,
    userList
}: RoleUserItemProps) => {
    return <Grid container spacing={5}>
        <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    value={
                        userList.filter(
                            (v: any) => roleStepParams.user_list.find(
                                (u) => u.user_id === v.user_id)
                        )
                    }
                    options={userList}
                    getOptionLabel={(option: any) => option.username}
                    onChange={(event, newValue: any) => {
                        changeParams({...roleStepParams,
                            user_list: newValue.map((v: any) => {
                                return {
                                    user_id: v.user_id,
                                    username: v.username
                                }
                            })
                        })
                    }}
                    renderInput={(params) => <TextField {...params} label="用户列表" variant="outlined"/>}
                />
            </FormControl>
        </Grid>
    </Grid>
}

export default RoleUserItem;
