/**
 * 判断是否为JSON 字符串
 * @param str
 */
export function isJSON(str: string) {
  try {
    const obj = JSON.parse(str);
    if (typeof obj === 'object' && obj) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
/**
 * any type to string
 * @param data
 */

export const anyTypeToString = (data: any) => {
  const type = typeof data;
  if (type === 'function') {
    return undefined;
  } else if (type === 'string') {
    return data;
  } else if (type === 'object') {
    return JSON.stringify(data);
  } else {
    return String(data);
  }
};

/**
 * string to object or string
 * @param data
 */
export const stringToData = (data: string) => {
  try {
    if (isJSON(data)) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return data;
  }
};
