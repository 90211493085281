import { BaseApi } from "../base";
import * as PATH_URL from "../constants/diff_setting_path";
import {ServicePlan, ServiceSetting} from "../../pages/types/Diff";

export const getList = async () => {
    try {
        const result = await BaseApi.get(PATH_URL.PLATFORM_LIST, {
            params: {
                rand: Math.random()
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getPlatformPlans = async (platform: string, plan?: string) => {
    try {
        const result: any[] = await BaseApi.get(PATH_URL.GET_PLAN_LIST, {
            params: {
                platform,
                plan
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getHistory = async (platform: string, plan: string) => {
    try {
        const result: any = await BaseApi.get(PATH_URL.HISTORY, {
            params: {
                platform,
                plan
            }
        });
        return Array.isArray(result) ? result : [];
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getPlatformState = async (platform: string) => {
    try {
        const result = await BaseApi.get(PATH_URL.GET_PLATFORM_SWITCH_STATUS, {
            params: {
                platform
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getPlanContent = async (platform: string, plan: string) => {
    try {
        const result = await BaseApi.get(PATH_URL.GET_PLAN_DETAIL, {
            params: {
                platform, plan
            }
        })
        return result;
    } catch (e) {
        return Promise.reject(e)
    }
}

export const getDict = async (platform: string) => {
    try {
        const result = await BaseApi.get(PATH_URL.GET_BASE_FILTER_MSG, {
            params: {
                platform
            }
        })
        return result;
    } catch (e) {
        return Promise.reject(e)
    }
}

export const savePlan = async (params: ServicePlan) => {
    try {
        await BaseApi.post(PATH_URL.EDIT_PLAN, params)
    } catch (e) {
        return Promise.reject(e)
    }
}

export const changePlatformSwitch = async (platform: string, switch_status: "on" | "off") => {
    try {
        await BaseApi.post(PATH_URL.CHANGE_PLATFORM_SWITCH_STATUS, {
            platform,
            switch_status
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveSetting = async (params: ServiceSetting) => {
    try {
        await BaseApi.post(PATH_URL.SAVE_CONFIG, params)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const verifySetting = async (platform: string, compare_plan: string, diff_config: string) => {
    try {
        const result = await BaseApi.post(PATH_URL.VERIFY_CONFIG, {
            platform,
            compare_plan,
            diff_config
        })
        return result;
        // return Promise.reject("params valid");
    } catch (e) {
        return Promise.reject(e);
    }
}

export const planConfigDetail = async (platform: string, plan: string) => {
    try {
        const result = await BaseApi.get(PATH_URL.PLAN_CONFIG_DETAIL, {
            params: {
                platform,
                plan
            }
        })
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const removePlan = async (platform: string, plan: string) => {
    try {
        const result = await BaseApi.post(PATH_URL.DEL_PLAN, {
            platform,
            plan
        })
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const addConfigItem = async (
    platform: string,
    config_type: string,
    setting_name: string
) => {
    try {
        const result = await BaseApi.post(PATH_URL.ADD_CONFIG_ITEM, {
            platform,
            config_type,
            setting_name,
            setting_value: {}
        })
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}
