import { NavGroup, NavLink, NavSectionTitle } from "../../../layouts/types";
import VerticalNavLink from "./VerticalNavLink";
import VerticalNavGroup from './VerticalNavGroup'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import {Settings} from "../../../context/settingsContext";

interface NavItemsProps {
    parent?: NavGroup
    navHover?: boolean
    navVisible?: boolean
    groupActive: string[]
    isSubToSub?: NavGroup
    currentActiveGroup: string[]
    settings: Settings
    saveSettings: (values: Settings) => void
    setGroupActive: (value: string[]) => void
    setCurrentActiveGroup: (item: string[]) => void
    verticalNavItems?: (NavLink | NavGroup | NavSectionTitle)[]
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
    if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle
    const navItem: any = (item as NavGroup).children;
    if (navItem && navItem.length > 0) return VerticalNavGroup

    return VerticalNavLink
}

const VerticalNavItems = (props: NavItemsProps) => {
    const { verticalNavItems } = props
    const RenderMenuItems = verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
        const TagName: any = resolveNavItemComponent(item)

        return <TagName {...props} key={index} item={item} />
    })

    return <>{RenderMenuItems}</>
}

export default VerticalNavItems
