import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import React, {ReactNode, useEffect, useState} from 'react'
import Navigation from "./Navigation"
import PageHeader from "./PageHeader"
import themeConfig from '../../configs/themeConfig'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { useSettings } from "../../hooks/useSettings"
import VerticalNavItems from "./NavItems"
import HomeHeader from "./components/HomeHeader";
import {Route, Routes, useNavigate} from 'react-router-dom';
import DiffList from "../DiffList";
import DiffSet from "../DiffSet";
import { BossItem } from "../../context/settingsContext";
import {
    get_boss_list,
    fetch_menu_list
} from "../../api/service/common"
import WebLocalStorageWorker from "../../utils/storage/web_storage";
import { CACHE_BOSS } from "../../utils/storage/constant";
import {useAuth} from "../../hooks/useAuth";
import Config from "../config";
import ERROR_PAGE from "../401";
import MenuManage from "../setting/user/MenuManage";
import TagManage from "../setting/user/TagManage";
import RoleManage from "../setting/user/RoleManage";
import UserManage from "../setting/user/UserManage";
import CacheBossPath from "../../service/cacheBossPath";

const VerticalLayoutWrapper = styled('div')({
    height: '100%',
    display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(6),
    transition: 'padding .25s ease-in-out',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))

type HomeProps = {
    children?: ReactNode
}

function PageHome(props: HomeProps) {
    // const { children } = props;
    const [navVisible, setNavVisible] = useState<boolean>(false)
    const { navigationSize, collapsedNavigationSize } = themeConfig
    const navWidth = navigationSize
    const collapsedNavWidth = collapsedNavigationSize
    const { settings, saveSettings } = useSettings()
    const [menus, setMenus] = useState<any[]>([]);
    const [bossList, setBossList] = useState<BossItem[]>([])
    const auth = useAuth()
    const navigate = useNavigate();
    console.log("settings", settings);

    const getBaseInfo = async () => {
        try {
            const boss_list: BossItem[] = [];
            const result: any = await get_boss_list();
            Object.keys(result).forEach((v: any) => {
                boss_list.push({
                    label: v,
                    value: result[v]
                })
            })
            setBossList(boss_list ? boss_list : []);
            const first_label = boss_list[0] && boss_list[0].label;
            // 如果没有默认的platform
            !settings.platform && first_label && saveSettings({...settings, platform: first_label})
            CacheBossPath.setCache(boss_list);
        } catch (e) {
            //
        }
    }

    const getMenuList = async (platform: string) => {
        try {
            const menu_result: any = await fetch_menu_list(
                platform,
                auth.user?.username
            );
            if (menu_result.length === 0) {
                navigate('/401', {replace: true})
            }
            setMenus(menu_result);
        } catch (e) {

        }
    }

    useEffect(() => {
        settings.platform && getMenuList(settings.platform)
    }, [settings.platform])

    useEffect(() => {
        console.log("useEffect", menus);
        auth.user && getBaseInfo();
    }, [auth.user])

    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    const toggleNavVisibility = () => {
        setNavVisible(!navVisible)
    }

    return (
        <VerticalLayoutWrapper>
            <Navigation
                navWidth={navWidth}
                navVisible={navVisible}
                setNavVisible={setNavVisible}
                collapsedNavWidth={collapsedNavWidth}
                toggleNavVisibility={toggleNavVisibility}
                hidden={hidden}
                settings={settings}
                saveSettings={(values) => {
                    saveSettings(values)
                }}
                verticalLayoutProps={{
                    navMenu: {
                        navItems: menus
                    }
                }}
                {...props}
            />
            <MainContentWrapper
                className='layout-content-wrapper'
            >
                <PageHeader
                    toggleNavVisibility={toggleNavVisibility}
                    settings={settings}
                    appBarContent={(props: any) => (
                        <HomeHeader
                            hidden={hidden}
                            settings={settings}
                            bossList={bossList}
                            saveSettings={saveSettings}
                            toggleNavVisibility={props.toggleNavVisibility}
                        />
                    )}
                />
                <ContentWrapper
                    className='layout-page-content'
                >
                    {/*{children}*/}
                    <Routes>
                        <Route path="different_config" element={<DiffList />} />
                        <Route path="different_config/:platform/:plan?" element={<DiffSet />} />
                        <Route path="different_config/:platform/:plan?" element={<DiffSet />} />
                        <Route path="config" element={<Config />} />
                        <Route path="edit" element={<Config />} />
                        <Route path="/menu" element={<MenuManage />} />
                        <Route path="/tag" element={<TagManage />} />
                        <Route path="/role" element={<RoleManage />} />
                        <Route path="/user" element={<UserManage />} />
                        <Route path="*" element={<ERROR_PAGE />} />
                    </Routes>
                </ContentWrapper>
            </MainContentWrapper>
        </VerticalLayoutWrapper>
    )
}

export default PageHome;
