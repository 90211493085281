import TabContext from '@mui/lab/TabContext'
import MuiTabList, { TabListProps } from '@mui/lab/TabList'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Icon from "../components/icon"
import React, { SyntheticEvent, useState, useEffect } from 'react'
import TabPanel from '@mui/lab/TabPanel'
import { styled } from '@mui/material/styles'
import DiffItem from "./DiffItem";
import TopMsg from "./TopMsg"
import { useParams, useNavigate } from 'react-router-dom';
import {
    getPlatformPlans,
    getHistory,
    getPlatformState,
    getPlanContent,
    getDict,
    savePlan,
    changePlatformSwitch,
    saveSetting,
    removePlan,
    addConfigItem
} from "../api/service/diff_setting";
import {GroupInfo, HistoryTypes, Plan, PlanInfoTypes, ServicePlan, ServiceSetting} from "./types/Diff";
import {deepClone, getNextLetter} from "../utils/common";
import { useSnackbar } from 'notistack';
import { useLoading } from '../useLoading';
import {makeUpToastParams} from "../utils/toast";

// ** Styled Tab component
const TabList = styled(MuiTabList)<TabListProps>(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'none'
    },
    '& .Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`
    },
    '& .MuiTab-root': {
        minWidth: 65,
        minHeight: 40,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.up('md')]: {
            minWidth: 130
        }
    }
}))
const DiffSet = () => {
    const defaultPlanInfo: PlanInfoTypes = {
        af_status: "",
        alias: "",
        campaign_id: [],
        country: [],
        media_source: [],
        parent_plan: "base",
        plan_switch: "",
        plan_name: "",
        vip: ''
    }
    const [platform, setPlatform] = useState<string>('');
    const [plan, setPlan] = useState<string>('');
    const [editHistory, setEditHistory] = useState<HistoryTypes[]>([])
    const [planList, setPlanList] = useState<Plan[]>([])
    const [editState, setEditState] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [platformSwitch, setPlatformSwitch] = useState(false);
    const [planInfo, setPlanInfo] = useState<PlanInfoTypes>(defaultPlanInfo);
    const [configContent, setConfigContent] = useState<any>({});
    const [planState, setPlanState] = useState<boolean>(false);
    const [afStatus, setAfStatus] = useState<any>({})
    const [campaignInfo, setCampaignInfo] = useState<any[]>([])
    const [mediaSource, setMediaSource] = useState<any[]>([])
    const [editPlanInfo, setEditPlanInfo] = useState<PlanInfoTypes>(defaultPlanInfo);
    const [firstConfigTab, setFirstConfigTab] = useState<string>('');
    const [secondConfigTab, setSecondConfigTab] = useState<string>('');
    const [jsonContent, setJsonContent] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const toggleLoading: any = useLoading();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState<boolean>(false)
    const handleChange = (event: SyntheticEvent, value: string) => {
        setPlan(value);
    }

    const onPlatformSwitch = async (status: boolean) => {
        try {
            toggleLoading(true);
            await changePlatformSwitch(platform, status ? "on" : "off");
            enqueueSnackbar(status ? "平台开关已开启" : "平台开关已关闭", makeUpToastParams());
            toggleLoading(false)
            setPlatformSwitch(status);
        } catch (e: any) {
            toggleLoading(false)
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const onSwitch = async (status: boolean) => {
        try {
            const {
                parent_plan, country, campaign_id, media_source, af_status, vip, alias, plan_name
            }: PlanInfoTypes = planInfo;
            const params: ServicePlan = {
                platform,
                plan: plan_name,
                group_info: {
                    campaign_id,
                    country,
                    media_source,
                    af_status,
                    vip
                },
                switch: status ? 'on' : 'off',
                parent_plan,
                alias
            }
            toggleLoading(true);
            await savePlan(params)
            enqueueSnackbar(status ? "分组开关已开启" : "分组开关已关闭", makeUpToastParams());
            toggleLoading(false);
            setPlanState(status);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const onClose = () => {
        setEditState(false);
    }

    const onSaveConfigItem = async (text: string) => {
        try {
            toggleLoading(true);
            await addConfigItem(platform, firstConfigTab, text);
            toggleLoading(false);
            enqueueSnackbar("新增成功", makeUpToastParams());
            queryCurrentPlanInfo(params.platform, plan, false);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const onConfigSave = async () => {
        try {
            const params: ServiceSetting = {
                platform,
                plan,
                config_type: firstConfigTab,
                setting_name: secondConfigTab,
                setting_value: jsonContent ? JSON.parse(jsonContent) : {}
            }
            toggleLoading(true);
            await saveSetting(params);
            toggleLoading(false);
            enqueueSnackbar("保存成功", makeUpToastParams());
            queryCurrentPlanInfo(params.platform, plan, false);
            queryHistory(plan);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const onSave = async () => {
        try {
            const {
                parent_plan, country, campaign_id, media_source, af_status, vip, alias, plan_switch, plan_name, invert
            }: PlanInfoTypes = editPlanInfo;
            const group_info: GroupInfo = {
                campaign_id,
                country: country.map((v) => invert ? "!" + v : v),
                media_source,
                af_status,
            };
            // 后台逻辑，不选不传key
            vip && (group_info.vip = vip);
            const params: ServicePlan = {
                platform,
                plan: plan_name,
                group_info,
                // switch: plan_switch,
                parent_plan,
                alias
            }
            // 后台逻辑，不选不传key
            plan_switch && (params.switch = plan_switch);
            toggleLoading(true);
            await savePlan(params);
            toggleLoading(false);
            enqueueSnackbar("保存成功", makeUpToastParams());
            if(modalTitle !== "添加分组") {
                queryCurrentPlanInfo(params.platform, plan, false);
                queryHistory(plan);
            }
            await (modalTitle === "添加分组" || alias !== planInfo.alias) && queryPlatFormPlan();
            if (modalTitle === "添加分组") {
                setPlan(plan_name);
            }
            setEditState(false)
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const showEditDialog = (type: 'add' | 'edit') => {
        type === 'edit' ? setEditPlanInfo(deepClone(planInfo)) : setEditPlanInfo(
            {
                ...defaultPlanInfo,
                plan_name: 'plan' + (planList.length > 0 ? getNextLetter(planList[planList.length-1].name.slice(-1)) : 'A')
            }
        );
        setEditState(true);
        setModalTitle(type === 'add' ? '添加分组' : '编辑分组');
    }

    const formChange = (info: PlanInfoTypes) => {
        setEditPlanInfo(info);
    }
    const params: any = useParams();
    useEffect(() => {
        setPlatform(params.platform);
        setPlan(params.plan)
        queryPlatFormPlan();
        queryPlatformSwitch();
        queryDict();
    }, []);

    useEffect(() => {
        if (plan) {
            setCollapsed(false);
            queryCurrentPlanInfo(params.platform, plan).then((content: any) => {
                if (firstConfigTab && secondConfigTab) {
                    const tabContent = content[firstConfigTab][secondConfigTab];
                    setJsonContent(tabContent ? JSON.stringify(tabContent, null, 2) : "");
                }
            });
            queryHistory(plan);
        }
    }, [plan])

    useEffect(() => {
        if (firstConfigTab && configContent) {
            const tabContent = configContent[firstConfigTab]
            setSecondConfigTab(Object.keys(tabContent)[0]);
        }
    }, [firstConfigTab])

    useEffect(() => {
        if (secondConfigTab) {
            const tabContent = configContent[firstConfigTab][secondConfigTab];
            setJsonContent(tabContent ? JSON.stringify(tabContent, null, 2) : "");
        }
    }, [secondConfigTab])

    const queryPlatFormPlan = async () => {
        try {
            toggleLoading(true)
            const result: any = await getPlatformPlans(params.platform);
            setPlanList(result);
            if (
                result.length > 0 &&
                (
                    (!params.plan && !plan) ||
                    (plan && !result.find((v: any) => v.name === plan)) ||
                    (!plan && params.plan && !result.find((v: any) => v.name === params.plan))
                )
            ) {
                setPlan(result[0].name);
            } else {
                // toggleLoading(false)
            }
        } catch (e: any) {
            toggleLoading(false)
            enqueueSnackbar(e, makeUpToastParams('error'));
            if (e === "gate permission denied") {
                navigate('/401', { replace: true });
            }
        }
    }

    const queryDict = async () => {
        try {
            const result: any = await getDict(params.platform);
            console.log("queryDict", result);
            const {af_status, campaign_id, media_source} = result;
            const campaign_list: any[] = [];
            const media_list: any[] = [];
            Object.keys(campaign_id).forEach((v) => {
                const current_parent = campaign_id[v] && campaign_id[v].parents && campaign_id[v].parents[0];
                v && v !=="undefined" && campaign_list.push({
                    value: v,
                    label: v + (current_parent ? `(${current_parent})` : ""),
                    media_source: current_parent
                })
            })
            Object.keys(media_source).forEach((v: string) => {
                const current_parent = media_source[v] && media_source[v].parents && media_source[v].parents[0];
                v && v !=="undefined" && media_list.push({
                    value: v,
                    label: v + (current_parent ? `(${current_parent})` : ""),
                    af_status: current_parent
                })
            })
            setAfStatus(af_status);
            setCampaignInfo(campaign_list);
            setMediaSource(media_list);
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const splitCategory = (str: string) => {
        const index = str.indexOf('##');
        return index >= 0 ? str.substring(index + 2) : "";
    }

    const onDelete = async () => {
        try {
            toggleLoading(true);
            await removePlan(platform, plan);
            enqueueSnackbar("删除成功", makeUpToastParams());
            queryPlatFormPlan();
            // toggleLoading(false);
        } catch (e: any) {
            toggleLoading(false);
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const queryHistory = async (plan: string) => {
        const edit_history: any[] = await getHistory(params.platform, plan);
        const result: HistoryTypes[] = [];
        edit_history.forEach((v: any) => {
            const {setting_value} = v;
            const temp_title: string[] = [];
            let temp_description: any = {};
            Array.isArray(setting_value) && setting_value.forEach((setting: any) => {
                const category = splitCategory(setting.platform_type);
                const key = category + "." + setting.setting_name
                temp_title.push(key);
                temp_description = {...temp_description, [key]: setting.setting_value}
            })
            result.push({
                timestamp: v.create_time,
                title: temp_title.join(", "),
                description: temp_description
            })
        })
        setEditHistory(result);
    }

    const queryPlatformSwitch = async () => {
        try {
            const result: any = await getPlatformState(params.platform);
            setPlatformSwitch(result.switch === 'on')
        } catch (e: any) {
            enqueueSnackbar(e, makeUpToastParams('error'));
        }
    }

    const queryCurrentPlanInfo = async (platform: string, plan: string, refreshTab: boolean = true) => {
        try {
            toggleLoading(true)
            const result: any = await getPlanContent(platform, plan);
            const { alias, parent_plan, group_info, plan_switch, content } = result;
            const { media_source, campaign_id, af_status, country, vip } = group_info;
            const planInfo: PlanInfoTypes = {
                alias,
                parent_plan,
                plan_switch,
                media_source: media_source ? media_source : [],
                campaign_id: campaign_id ? campaign_id : [],
                af_status: af_status ? af_status : '',
                country: country ? country && country.map((v: string) => {
                    if (v.charAt(0) === '!') {
                        return v.slice(1);
                    }
                    return v;
                }) : [],
                vip,
                plan_name: plan,
                invert: country && country.some((v: string) => v.charAt(0) === '!')
            }
            setPlanState(plan_switch === 'on');
            setPlanInfo(planInfo);
            setConfigContent(content)
            if (content && refreshTab) {
                const firstTab: any = Object.keys(content);
                setFirstConfigTab(firstTab && firstTab[firstTab.length - 1]);
            }
            toggleLoading(false)
            return content;
        } catch (e) {
            //
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Box sx={{mb: 6}}>
                <TopMsg
                    platform={platform}
                    onEdit={showEditDialog}
                    onSwitch={onPlatformSwitch}
                    platformSwitch={platformSwitch}
                />
            </Box>
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <TabContext value={plan ? plan : ""}>
                    <TabList
                        variant='scrollable'
                        scrollButtons='auto'
                        onChange={handleChange}
                    >
                        {planList.map((v) => (
                            <Tab
                                value={v.name}
                                key={v.name}
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { mr: 2 }, textTransform: 'none' }}>
                                        <Icon fontSize={20} icon='mdi:bookmark-outline' />
                                        {v.name + (v.alias ? "-" + v.alias : "")}
                                    </Box>
                                }
                            />
                        ))}
                    </TabList>
                    <Box sx={{ mt: 4, flex: 1 }}>
                        <TabPanel sx={{ p: 0, height: "100%" }} value={plan ? plan : ''}>
                            <DiffItem
                                onEdit={showEditDialog}
                                info={planInfo}
                                editHistory={editHistory}
                                onSwitch={onSwitch}
                                status={planState}
                                onClose={onClose}
                                editStatus={editState}
                                modalTitle={modalTitle}
                                afStatus={afStatus}
                                campaignInfo={campaignInfo}
                                mediaSource={mediaSource}
                                formChange={formChange}
                                editPlanInfo={editPlanInfo}
                                planList={planList}
                                onSave={onSave}
                                configContent={configContent}
                                firstTab={firstConfigTab}
                                secondTab={secondConfigTab}
                                setFirstTab={setFirstConfigTab}
                                setSecondTab={setSecondConfigTab}
                                jsonContent={jsonContent}
                                onJsonChange={setJsonContent}
                                onConfigSave={onConfigSave}
                                platform={platform}
                                plan={plan}
                                onDelete={onDelete}
                                collapsed={collapsed}
                                setCollapsed={setCollapsed}
                                onSaveConfigItem={onSaveConfigItem}
                            />
                        </TabPanel>
                    </Box>
                </TabContext>
            </Box>
        </Box>
    )
}
export default DiffSet;
