import Divider from '@mui/material/Divider'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import MuiListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader'
import { Settings, NavSectionTitle } from "../../../context/settingsContext"

interface TitleProps {
    navHover: boolean
    settings: Settings
    item: NavSectionTitle
    collapsedNavWidth: number
}

const ListSubheader = styled((props: ListSubheaderProps) => <MuiListSubheader component='li' {...props} />)(
    ({ theme }) => ({
        lineHeight: 1,
        display: 'flex',
        position: 'static',
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent'
    })
)

const TypographyHeaderText = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '0.75rem',
    lineHeight: 'normal',
    letterSpacing: '0.21px',
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium
}))

const VerticalNavSectionTitle = (props: TitleProps) => {
    const { item, navHover, settings, collapsedNavWidth } = props
    const theme = useTheme()

    const { navCollapsed } = settings

    const conditionalColors = () => {
        return {
            '& .MuiTypography-root': {
                color: 'text.disabled'
            },
            '& .MuiDivider-root:before, & .MuiDivider-root:after, & hr': {
                borderColor: `rgba(${theme.palette.customColors.main}, ${navCollapsed && !navHover ? 0.3 : 0.12})`
            }
        }
    }

    return (
        <ListSubheader
            className='nav-section-title'
            sx={{
                ...conditionalColors(),
                ...(navCollapsed && !navHover
                    ? {
                        py: 3.5,
                        pr: (collapsedNavWidth - 24) / 8 - 1,
                        pl: (collapsedNavWidth - 24) / 8 + 0.25
                    }
                    : { px: 0, py: 1.75 })
            }}
        >
            <Divider
                textAlign='left'
                sx={{
                    m: 0,
                    lineHeight: 'normal',
                    ...(navCollapsed && !navHover
                        ? { width: 22 }
                        : {
                            width: '100%',
                            textTransform: 'uppercase',
                            '&:before, &:after': { top: 7, transform: 'none' },
                            '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' }
                        })
                }}
            >
                {navCollapsed && !navHover ? null : (
                    <TypographyHeaderText noWrap>
                        {item.sectionTitle}
                    </TypographyHeaderText>
                )}
            </Divider>
        </ListSubheader>
    )
}

export default VerticalNavSectionTitle
