import MuiSwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'
import {Settings} from "../../context/settingsContext";

interface DrawerProps {
    hidden: boolean
    navHover: boolean
    navVisible: boolean
    setNavHover: (values: boolean) => void
    setNavVisible: (value: boolean) => void
    children: ReactNode
    navWidth: number
    collapsedNavWidth: number
    settings: Settings
}

const SwipeableDrawer = styled(MuiSwipeableDrawer)<SwipeableDrawerProps>({
    overflowX: 'hidden',
    transition: 'width .25s ease-in-out',
    '& ul': {
        listStyle: 'none'
    },
    '& .MuiListItem-gutters': {
        paddingLeft: 4,
        paddingRight: 4
    },
    '& .MuiDrawer-paper': {
        left: 'unset',
        right: 'unset',
        overflowX: 'hidden',
        transition: 'width .25s ease-in-out, box-shadow .25s ease-in-out'
    }
})

const Drawer = (props: DrawerProps) => {
    const {
        hidden,
        navHover,
        navVisible,
        settings,
        setNavHover,
        setNavVisible,
        collapsedNavWidth,
        navWidth,
        children
    } = props;

    const { navCollapsed } = settings

    const drawerColors = () => {
        return {
            backgroundColor: 'background.default'
        }
    }

    const MobileDrawerProps = {
        open: navVisible,
        onOpen: () => setNavVisible(true),
        onClose: () => setNavVisible(false),
        ModalProps: {
            keepMounted: true // Better open performance on mobile.
        }
    }

    // Drawer Props for Laptop & Desktop screens
    const DesktopDrawerProps = {
        open: true,
        onOpen: () => null,
        onClose: () => null,
        onMouseEnter: () => {
            setNavHover(true)
        },
        onMouseLeave: () => {
            setNavHover(false)
        }
    }

    return (
        <SwipeableDrawer
            className='layout-vertical-nav'
            variant={hidden ? 'temporary' : 'permanent'}
            {...(hidden ? { ...MobileDrawerProps } : { ...DesktopDrawerProps })}
            PaperProps={{
                sx: {
                    ...drawerColors(),
                    width: navCollapsed && !navHover ? collapsedNavWidth : navWidth,
                    ...(!hidden && navCollapsed && navHover ? { boxShadow: 9 } : {}),
                    borderRight: 0
                }
            }}
            sx={{
                width: navCollapsed ? collapsedNavWidth : navWidth
            }}
        >
            {children}
        </SwipeableDrawer>
    )
}

export default Drawer;
