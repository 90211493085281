import TreeNode, { TreeNodeProps } from "./TreeNode"
import { css } from '@emotion/css';

export interface TreeProps {
    label: TreeNodeProps['label'];
    lineHeight?: string;
    lineWidth?: string;
    lineColor?: string;
    lineStyle?: 'dashed' | 'dotted' | 'double' | 'solid' | string;
    lineBorderRadius?: string;
    children: TreeNodeProps['children'];
    nodePadding?: string;
}

const Tree = ({
    children,
    label,
    lineStyle = 'solid',
    lineColor = "#000",
    lineHeight = '1px',
    nodePadding = '5px',
    lineWidth = '30px',
    lineBorderRadius = '5px',
}: TreeProps) => {
    return (
        <div className={css`
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          --line-height: ${lineHeight};
          --line-width: ${lineWidth};
          --line-color: ${lineColor};
          --line-border-radius: ${lineBorderRadius};
          --line-style: ${lineStyle};
          --node-padding: ${nodePadding};
          --tree-line-height: var(--line-height, 1px);
          --tree-line-width: var(--line-width, 30px);
          --tree-line-color: var(--line-color, black);
          --tree-line-border-radius: var(--line-border-radius, 5px);
          --tree-node-line-style: var(--line-style, solid);
          --tree-node-padding: var(--node-padding, 5px);
        `}>
            <TreeNode className={css`margin: 0 auto; padding-right: 200px!important;`} label={label}>{children}</TreeNode>
        </div>
    )
}

export default Tree;
