import Dialog from '@mui/material/Dialog'
import Icon from "../components/icon"
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Fade, { FadeProps } from '@mui/material/Fade'
import React, { forwardRef, Ref, ReactElement, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {Plan} from "./types/Diff";
import "./DiffDialog.css";

const Transition = forwardRef(function Transition(
    props: FadeProps & { children?: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Fade ref={ref} {...props} />
})

interface Props {
    show: boolean,
    setShow: (show: boolean) => void,
    planList: Plan[],
    modalTitle: string,
    rightJson: string,
    leftJson: string,
    comparePlan: string,
    currentPlan: string,
    onConfigSave: () => void,
    setComparePlan: (plan: string, all: boolean) => void
}

const DiffDialog = (
    {
        show,
        setShow,
        planList,
        modalTitle,
        rightJson,
        leftJson,
        comparePlan,
        onConfigSave,
        setComparePlan,
        currentPlan
    }: Props
) => {
    const [splitGroupModalView, setSplitGroupModalView] = useState(true);
    const newStyles = {
        variables: {
            dark: {
                diffViewerTitleColor: '#fefed5',
                codeFoldContentColor: '#fefed5',
                addedGutterColor: '#fefed5',
                highlightGutterBackground: '#ffcd3c',
            },
        }
    };
    return <Dialog
        fullWidth
        open={show}
        scroll='paper'
        maxWidth='lg'
        PaperProps={{
            style: {
                maxWidth: '90%', // 可选，设置对话框的最大宽度
            },
        }}
        onClose={() => setShow(false)}
        TransitionComponent={Transition}
        onBackdropClick={() => setShow(false)}
    >
        <DialogContent sx={{ px: { xs: 8, sm: 15 }, py: { xs: 8, sm: 12.5 }, position: 'relative' }}>
            <IconButton
                size='small'
                onClick={() => setShow(false)}
                sx={{ position: 'absolute', right: '1rem', top: '1rem' }}
            >
                <Icon icon='mdi:close' />
            </IconButton>
            <Box sx={{ mb: 4, textAlign: 'center' }}>
                <Typography variant='h5' sx={{ mb: 3 }}>
                    {modalTitle}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', mb: 3, alignItems: 'center', justifyContent: 'space-between' }}>
                <FormControl sx = {{mr: 4}}>
                    <InputLabel id='status-select'>比较分组</InputLabel>
                    <Select
                        value={comparePlan}
                        fullWidth
                        labelId='status-select'
                        label='Status'
                        onChange={
                            (e) => setComparePlan(e.target.value, modalTitle!=='配置校验')
                        }>
                        {planList.map((v: any) => (
                            <MenuItem key={v.name} value={v.name}>{v.alias ? v.name + "-" + v.alias : v.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <InputLabel
                        sx={{ fontWeight: 600, cursor: 'pointer', fontSize: '0.875rem', color: 'text.secondary' }}
                        onClick={() => {setSplitGroupModalView(false)}}
                    >
                        一栏比较
                    </InputLabel>
                    <Switch
                        checked={splitGroupModalView}
                        onChange={(e: any) => {
                            setSplitGroupModalView(e.target.checked)}
                        }
                    />
                    <InputLabel
                        onClick={() => {setSplitGroupModalView(true)}}
                        sx={{ fontWeight: 600, cursor: 'pointer', fontSize: '0.875rem', color: 'text.secondary' }}
                    >
                        两栏比较
                    </InputLabel>
                </Box>
            </Box>
            <Box className="diff-container" sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <ReactDiffViewer
                    styles={newStyles}
                    oldValue={leftJson}
                    newValue={rightJson}
                    splitView={splitGroupModalView}
                    leftTitle={(splitGroupModalView && comparePlan === currentPlan) ? comparePlan + "(历史)" : comparePlan}
                    rightTitle={currentPlan}
                    useDarkTheme={true}
                />
            </Box>
            {modalTitle==='配置校验' && <Box sx={{ mt: 6, textAlign: 'center' }}>
                <Button startIcon={
                    <Icon icon='carbon:save' style={{fontSize: 18}}/>
                } variant='contained' onClick={async () => {
                    await onConfigSave();
                    setShow(false)
                }}>
                    确认保存
                </Button>
            </Box>}
        </DialogContent>
    </Dialog>
}

export default DiffDialog;
