// ** React Imports
import React, { useEffect, useCallback, useState } from 'react'

// ** Next Import
import { Link } from 'react-router-dom';

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { SelectChangeEvent } from '@mui/material/Select'

// ** Icon Imports
import Icon from "../../../components/icon"

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Custom Components Imports
import CustomChip from '../../../components/chip'
import CustomAvatar from '../../../components/avatar'

// ** Utils Import
import { getInitials } from '../../../utils/get-initials'

// ** Actions Imports
import { fetchData, deleteUser } from '../../../store/user'

// ** Types Imports
import { RootState, AppDispatch } from '../../../store'
import { ThemeColor } from '../../../layouts/types'

// ** Custom Components Imports
import TableHeader from '../../../components/page-header/TableHeader'
import PageHeader from "../../../components/page-header";
import { UserRoleTypes, UserType } from "../../types/Menu";
import { user_detail } from "../../../api/service/common";
import {makeUpToastParams} from "../../../utils/toast";
import {useSnackbar} from "notistack";

interface CellType {
    row: UserType
}

// ** renders client column
const renderClient = (row: UserType) => {
    return (
        <CustomAvatar skin='light' color="primary" sx={{ mr: 3, width: 30, height: 30, fontSize: '.875rem' }}>
            {row.username.slice(0, 2)}
        </CustomAvatar>
    )
}

const UserManage = () => {
    // ** State
    const [pageSize, setPageSize] = useState<number>(10)
    const [searchText, setSearchText] = useState<string>('')
    const [modalTitle, setModalTitle] = useState<string>('')
    const [showUserList, setShowUserList] = useState<UserType[]>([])
    const [editItem, setEditItem] = useState<UserType>()

    // ** Hooks
    const dispatch = useDispatch<AppDispatch>()
    const store: any = useSelector((state: RootState) => state.user)
    const userList = store.users;

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(
            fetchData()
        )
    }, [dispatch])

    useEffect(() => {
        const list = searchText ?
            userList.filter(
                (v: UserType) => v.username.toLowerCase().includes(searchText.toLowerCase())
            ) : userList;
        setShowUserList([...list])
    }, [searchText, userList])

    const handleFilter = useCallback((val: string) => {
        setSearchText(val)
    }, [])

    const getRowId = (row: UserType) => {
        return row.user_id; // 使用 name 属性作为唯一标识符
    };

    const setDetail = async (row: UserType) => {
        setEditItem({...row});
        setModalTitle('编辑用户')
    }

    const columns = [
        {
            flex: 0.2,
            minWidth: 230,
            field: 'nick_name',
            headerName: '用户名',
            filterable: false,
            renderCell: ({ row }: CellType) => {
                const { username } = row

                return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {renderClient(row)}
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <Typography
                                noWrap
                                variant='body2'
                                sx={{
                                    fontWeight: 600,
                                    color: 'text.primary',
                                    textDecoration: 'none',
                                    '&:hover': { color: theme => theme.palette.primary.main }
                                }}
                            >
                                {username}
                            </Typography>
                        </Box>
                    </Box>
                )
            }
        },
        {
            flex: 0.15,
            field: 'role',
            minWidth: 150,
            headerName: '角色',
            filterable: false,
            renderCell: ({ row }: CellType) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { mr: 3 } }}>
                        {
                            row.roles.map((v) => (
                                <Typography variant='body2' key={v.role_id} noWrap>
                                    {v.role_name}
                                </Typography>
                            ))
                        }
                    </Box>
                )
            }
        },
        {
            flex: 0.2,
            field: 'phone',
            minWidth: 150,
            headerName: '电话',
            sortable: false,
            filterable: false,
            renderCell: ({ row }: CellType) => {
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { mr: 3 } }}>
                        <Typography noWrap variant='body2'>
                            {row.phone}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            flex: 0.1,
            minWidth: 100,
            field: 'actions',
            headerName: '操作',
            sortable: false,
            filterable: false,
            renderCell: ({ row }: CellType) => (
                <div>
                    <IconButton onClick={() => setDetail(row)}>
                        <Icon icon='carbon:edit' fontSize={18} />
                    </IconButton>
                    <IconButton onClick={() => {
                        dispatch(deleteUser(row.user_id))
                    }}>
                        <Icon icon='carbon:trash-can' fontSize={18}/>
                    </IconButton>
                </div>
            )
        }
    ]

    return (
        <Grid container spacing={6}>
            <PageHeader
                title={<Typography variant='h6'>用户列表</Typography>}
            />
            <Grid item xs={12}>
                <Card>
                    <TableHeader
                        modalTitle={modalTitle}
                        value={searchText}
                        handleFilter={handleFilter}
                        setModalTitle={setModalTitle}
                        editItem={editItem}
                    />
                    <DataGrid
                        autoHeight
                        rows={showUserList}
                        columns={columns}
                        getRowId={getRowId}
                        checkboxSelection
                        pageSize={pageSize}
                        disableSelectionOnClick
                        rowsPerPageOptions={[10, 25, 50]}
                        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                        disableColumnMenu
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default UserManage
