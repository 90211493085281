import React, {useState} from 'react';
import ThemeComponent from './theme/ThemeComponent'
import {styled} from '@mui/material/styles'
import DiffList from "./pages/DiffList";
import PageHome from "./pages/layouts/PageHome";
import DiffSet from "./pages/DiffSet";
import Config from "./pages/config";
import UserManage from "./pages/setting/user/UserManage";
import MenuManage from "./pages/setting/user/MenuManage";
import ERROR_PAGE from "./pages/401";
import Login from "./pages/login";
import 'react-perfect-scrollbar/dist/css/styles.css'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingContext from './LoadingContext';
import {CircularProgress} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import {SettingsConsumer, SettingsProvider} from "./context/settingsContext";
import {AuthProvider} from "./context/AuthContext"
import AuthGuard from "./context/AuthGuard";
import { Provider } from 'react-redux'
import { store } from "./store";
import TagManage from "./pages/setting/user/TagManage";

const ContentWrapper = styled('main')(({theme}) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

function App() {
  const [isLoading, setIsLoading] = useState(false);

  // 显示或隐藏 loading
  const toggleLoading: any = (loading: boolean) => {
    setIsLoading(loading);
  };

  return (
    <Provider store={store}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
                <ThemeComponent settings={settings}>
                  <LoadingContext.Provider value={toggleLoading}>
                    <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: 1301 }}>
                      <CircularProgress color="inherit" />
                    </Backdrop>

                    <ContentWrapper
                        sx={{p: 0}}
                        className='layout-page-content'
                    >
                      <Router>
                        <AuthProvider>
                          <AuthGuard>
                            <Routes>
                              <Route path="/" element={<PageHome />}>
                                <Route path="different_config" element={<DiffList />} />
                                <Route path="different_config/:platform/:plan?" element={<DiffSet />} />
                                <Route path="config" element={<Config />} />
                                <Route path="edit" element={<Config />} />
                                <Route path="/401" element={<ERROR_PAGE />} />
                                <Route path="/user" element={<UserManage />} />
                                <Route path="/menu" element={<MenuManage />} />
                                <Route path="/tag" element={<TagManage />} />
                                <Route path="*" element={<ERROR_PAGE />} />
                              </Route>
                              <Route path="/login" element={<Login />} />
                            </Routes>
                          </AuthGuard>
                        </AuthProvider>
                      </Router>
                    </ContentWrapper>
                  </LoadingContext.Provider>
                </ThemeComponent >
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </Provider>
  );
}

export default App;
