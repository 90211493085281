// ** Redux Imports
import { Dispatch } from 'redux'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {add_user, fetch_user_list, delete_user} from "../../api/service/common";
import {ServiceUsersType, UserItemType} from "../../pages/types/Menu";

// ** Axios Imports
import axios from 'axios'

interface DataParams {
  page: number
}

interface Redux {
  getState: any
  dispatch: Dispatch<any>
}

// ** Fetch Users
export const fetchData = createAsyncThunk(
    'userList/fetchData',
    async () => {
        const response = await fetch_user_list();
        return response
})

// ** Add User
export const addUser = createAsyncThunk(
  'userList/addUser',
  async (data: UserItemType, { getState, dispatch }: Redux) => {
    // const response = await axios.post('/apps/users/add-user', {
    //   data
    // })
    const response = await add_user(data);
    dispatch(fetchData())

    return response;
  }
)

// ** Delete User
export const deleteUser = createAsyncThunk(
  'userList/deleteUser',
  async (user_id: string, { getState, dispatch }: Redux) => {
    const response = await delete_user(user_id);
    dispatch(fetchData())

    return response
  }
)

export const userListSlice: any = createSlice({
    name: 'userList',
    initialState: {
        users: [],
        total_count: 1
    },
    reducers: {
        emptyReducer: (state) => state,
    },
    extraReducers: builder => {
        builder.addCase(fetchData.fulfilled, (state: ServiceUsersType, action) => {
            state.users = action.payload.users;
        })
    }
})

export default userListSlice.reducer
