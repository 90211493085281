// ** React Imports
import React, { useEffect, useCallback, useState } from 'react'

// ** Next Import
import { Link } from 'react-router-dom';

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { SelectChangeEvent } from '@mui/material/Select'

// ** Icon Imports
import Icon from "../../components/icon"

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Custom Components Imports
import CustomChip from '../../components/chip'
import CustomAvatar from '../../components/avatar'

// ** Utils Import
import { getInitials } from '../../utils/get-initials'

// ** Actions Imports
import { fetchData } from '../../store/user'

// ** Types Imports
import { RootState, AppDispatch } from '../../store'
import { ThemeColor } from '../../layouts/types'

// ** Custom Components Imports
import PageHeader from "../../components/page-header";
import TableHeader from "../../components/page-header/TableHeader";

interface UserRoleType {
    [key: string]: { icon: string; color: string }
}

interface UserStatusType {
    [key: string]: ThemeColor
}

type UsersType = {
    id: number
    fullName: string
    username: string
    role: string
}
interface CellType {
    row: UsersType
}

// ** Vars
const userRoleObj: UserRoleType = {
    admin: { icon: 'mdi:laptop', color: 'error.main' },
    author: { icon: 'mdi:cog-outline', color: 'warning.main' },
    editor: { icon: 'mdi:pencil-outline', color: 'info.main' },
    maintainer: { icon: 'mdi:chart-donut', color: 'success.main' },
    subscriber: { icon: 'mdi:account-outline', color: 'primary.main' }
}

const userStatusObj: UserStatusType = {
    active: 'success',
    pending: 'warning',
    inactive: 'secondary'
}

// ** renders client column
const renderClient = (row: UsersType) => {
    return (
        <CustomAvatar skin='light' color="primary" sx={{ mr: 3, width: 30, height: 30, fontSize: '.875rem' }}>
            {getInitials(row.fullName ? row.fullName : 'John Doe')}
        </CustomAvatar>
    )
}

const columns = [
    {
        flex: 0.2,
        minWidth: 230,
        field: 'fullName',
        headerName: 'User',
        renderCell: ({ row }: CellType) => {
            const { fullName, username } = row

            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {renderClient(row)}
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                        <Typography
                            noWrap
                            variant='body2'
                            component={Link}
                            to='/apps/user/view/overview/'
                            sx={{
                                fontWeight: 600,
                                color: 'text.primary',
                                textDecoration: 'none',
                                '&:hover': { color: theme => theme.palette.primary.main }
                            }}
                        >
                            {fullName}
                        </Typography>
                        <Typography noWrap variant='caption'>
                            {`@${username}`}
                        </Typography>
                    </Box>
                </Box>
            )
        }
    },
    {
        flex: 0.15,
        field: 'role',
        minWidth: 150,
        headerName: 'Role',
        renderCell: ({ row }: CellType) => {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { mr: 3, color: userRoleObj[row.role].color } }}>
                    <Icon icon={userRoleObj[row.role].icon} fontSize={20} />
                    <Typography noWrap sx={{ color: 'text.secondary', textTransform: 'capitalize' }}>
                        {row.role}
                    </Typography>
                </Box>
            )
        }
    },
    {
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        field: 'actions',
        headerName: 'Actions',
        renderCell: () => (
            <IconButton component={Link} to='/apps/user/view/overview/'>
                <Icon icon='mdi:eye-outline' />
            </IconButton>
        )
    }
]

const Config = () => {
    // ** State
    const [value, setValue] = useState<string>('')
    const [pageSize, setPageSize] = useState<number>(10)

    // ** Hooks
    const dispatch = useDispatch<AppDispatch>()
    const store = useSelector((state: RootState) => state.user)
    console.log("store", store);

    useEffect(() => {
        dispatch(
            fetchData()
        )
    }, [dispatch, value])

    const handleFilter = useCallback((val: string) => {
        setValue(val)
    }, [])


    return (
        <Grid container spacing={6}>
            <PageHeader
                title={<Typography variant='h6'>角色列表</Typography>}
            />
            <Grid item xs={12}>
                <Card>
                    {/*<TableHeader*/}
                    {/*    value={value}*/}
                    {/*    handleFilter={handleFilter}*/}
                    {/*/>*/}
                    {/*<DataGrid*/}
                    {/*    autoHeight*/}
                    {/*    rows={store && store.data}*/}
                    {/*    columns={columns}*/}
                    {/*    checkboxSelection*/}
                    {/*    pageSize={pageSize}*/}
                    {/*    disableSelectionOnClick*/}
                    {/*    rowsPerPageOptions={[10, 25, 50]}*/}
                    {/*    onPageSizeChange={newPageSize => setPageSize(newPageSize)}*/}
                    {/*/>*/}
                </Card>
            </Grid>
        </Grid>
    )
}

export default Config
