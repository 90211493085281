import { BaseApi } from "../base";
import {
    BOSS_LIST,
    MENUS,
    ROLES,
    EDIT_MENU,
    MENU,
    ROLE_MENU,
    USERS,
    ROLE_MENU_LIST,
    DEL_MENU,
    ADD_ROLE,
    DEL_ROLE,
    ROLE_BIND_USERS,
    ADD_USER,
    DEL_USER,
    USER_DETAIL, ROLE_USERS, EDIT_ROLE
} from "../constants/common_path";
import {
    MenuTypes,
    ServiceRoleMenuType,
    ServiceRoleType,
    UserRoleTypes,
    UserItemType
} from "../../pages/types/Menu";
import { PlatformType } from "../../context/settingsContext";

export const get_boss_list = async () => {
    try {
        const result = await BaseApi.get(BOSS_LIST)
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetch_menu_list = async (boss: string, username?: string) => {
    try {
        const result: any = await BaseApi.get(MENUS, {
            params: {
                username
            },
            data: {
                boss
            }
        })
        return result ? result.menu : [];
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetch_user_list = async () => {
    try {
        const result: any = await BaseApi.get(USERS, {
            data: {

            }
        })
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetch_role_list = async () => {
    try {
        const result: any = await BaseApi.get(ROLES)
        return result.roles;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const edit_menu = async (data: MenuTypes) => {
    try {
        const result = await BaseApi.post(EDIT_MENU, data)
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const add_menu = async (data: MenuTypes) => {
    try {
        const result = await BaseApi.post(MENU, data)
        return result;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const delete_menu = async (id: string | number, boss: PlatformType) => {
    try {
        await BaseApi.delete(DEL_MENU(id.toString()), {
            data: {
                boss
            }
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetch_role_menu = async (role_id: number, boss: PlatformType) => {
    try {
        const result: any = await BaseApi.get(ROLE_MENU, {
            params: {
                role_id,
                boss
            }
        })
        return result.menu_ids;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetch_role_menu_list = async (boss: PlatformType) => {
    try {
        const result: any = await BaseApi.get(ROLE_MENU_LIST, {
            data: {
                boss
            }
        })
        return result ? result.role_menus : [];
    } catch (e) {
        return Promise.reject(e);
    }
}

export const set_role_menus = async (role_menus: ServiceRoleMenuType[], boss: PlatformType) => {
    try {
        await BaseApi.post(ROLE_MENU_LIST, {
            role_menus,
            boss
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const add_role = async (service_role: ServiceRoleType) => {
    try {
        const result: any = await BaseApi.post(ADD_ROLE, service_role)
        return result.role && result.role.id;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const del_role = async (role_id: string, boss: PlatformType) => {
    try {
        await BaseApi.delete(DEL_ROLE(role_id), {
            data: {
                boss
            }
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const role_bind_users = async (user_roles: UserRoleTypes[], boss: PlatformType) => {
    try {
        await BaseApi.post(ROLE_BIND_USERS, {
            user_roles,
            boss
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const add_user = async (data: UserItemType) => {
    try {
        await BaseApi.post(ADD_USER, data)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const delete_user = async (user_id: string) => {
    try {
        await BaseApi.delete(DEL_USER(user_id))
    } catch (e) {
        return Promise.reject(e);
    }
}

export const user_detail = async (user_id: string) => {
    try {
        await BaseApi.get(USER_DETAIL, {
            params: {
                user_id
            }
        })
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetch_role_users = async () => {
    try {
        const result: any = await BaseApi.get(ROLE_USERS);
        return result ? result.role_users : []
    } catch (e) {
        return Promise.reject(e);
    }
}

export const edit_role = async (params: ServiceRoleType) => {
    try {
        await BaseApi.post(EDIT_ROLE, params)
        return params.id
    } catch (e) {
        return Promise.reject(e);
    }
}

