import axios from "axios";
import WebLocalStorageWorker from "../../utils/storage/web_storage";
import {USER_INFO} from "../../utils/storage/constant";
import CacheBossPath from "../../service/cacheBossPath";

export const BaseApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000
})

BaseApi.interceptors.request.use((config) => {
    console.log("config.data?.boss", config.data?.boss);
    const boss = config.data?.boss;
    const boss_list = CacheBossPath.get();
    const path = boss && boss_list.find((v: any) => v.label === boss).value;
    if (path && path !== BaseApi.defaults.baseURL) {
        config.baseURL = path;
    }
    return config;
})

// 添加请求拦截器
BaseApi.interceptors.request.use(
    (config) => {
        const userData = WebLocalStorageWorker.get(USER_INFO);
        const headers: any = {
            ...config.headers
        }
        userData && userData.token && (headers.Authorization = userData.token);
        return {
            ...config,
            headers
        }
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 开始解密的处理和返回
BaseApi.interceptors.response.use(
    (response) => {
        try {
            const { data } = response;
            console.log("response", response, data);
            if (data.meta && data.meta.code === 200 && data.data) {
                return data.data;
            } else if (!data.meta && data.data && !data.err) {
                return data.data;
            } else if (!data.meta && data.status === "failed") {
                return Promise.reject(data.err);
            } else if (data.meta && data.meta.code !== 200) {
                console.log("data.meta.message", data.meta.message)
                window.location.href = "/login"
                return Promise.reject(data.meta.message);
            } else {
                return Promise.reject(data.msg);
            }
        } catch (error) {
            console.log("interceptors error", error);
            return Promise.reject(error);
        }
    },
    (error: any) => {
        console.log("error", error, error.response, error instanceof Error);
        const { response } = error;
        const { data } = response;
        const message = data && data.meta && data.meta.message;
        console.log("message", message);
        return Promise.reject(message ? message : error);
    }
);
