import BlankLayout from "../../components/layout/BlankLayout"
import Box from '@mui/material/Box'
import { useState } from "react";
import MuiCard, { CardProps } from '@mui/material/Card'
import { styled, useTheme } from '@mui/material/styles'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import themeConfig from '../../configs/themeConfig'
import Icon from '../../components/icon'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FooterIllustrations from "../../components/layout/FooterIllustrations";
import
    MuiFormControlLabel,
{
    FormControlLabelProps
} from '@mui/material/FormControlLabel'
import { useAuth } from '../../hooks/useAuth'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormHelperText from '@mui/material/FormHelperText'
import md5 from "js-md5";

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
    [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary
    }
}))

const schema = yup.object().shape({
    Username: yup.string().required(),
    Password: yup.string().min(5).required(),
    Verification_code: yup.string().min(5).max(6).required()
})

const defaultValues = {
    Password: '',
    Username: '',
    Verification_code: ''
}

interface FormData {
    Password: string
    Username: string,
    Verification_code: string
}

const Login = () => {
    const [rememberMe, setRememberMe] = useState<boolean>(true)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const theme = useTheme()
    const auth = useAuth()

    let [countDown, setCountDown] = useState<any>(0);

    const {
        control,
        setError,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(schema)
    })

    const getVerifyCode = async (e: any) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            const forms = getValues();
            const {Username} = forms;
            await auth.sendCode(Username);
            setCountDown(60)
            let timer = setInterval(() => {
                setCountDown((prevCount: number) => {
                    if (prevCount && prevCount > 0) {
                        return prevCount - 1
                    } else {
                        clearInterval(timer);
                    }
                }); // 更新倒计时时间
            }, 1000);

            // 在组件销毁时清除定时器
            return () => {
                clearInterval(timer);
            };
        } catch (e) {

        }
    }

    const onSubmit = async (data: FormData) => {
        const { Username, Password, Verification_code } = data
        try {
            console.log("onSubmit");
            const result = await auth.login({
                username: Username,
                password: md5(Password),
                rememberMe,
                phone_code: Verification_code
            })
            console.log("result", result)
        } catch (e: any) {
            console.log("error", e);
            setError(e.includes("验证码") ? "Verification_code" : "Username", {
                type: 'manual',
                message: e
            })
        }
    }

    return (
        <BlankLayout>
            <Box className='content-center'>
                <Card sx={{ zIndex: 1 }}>
                    <CardContent sx={{ p: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                        <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <svg
                                width={35}
                                height={29}
                                version='1.1'
                                viewBox='0 0 30 23'
                                xmlns='http://www.w3.org/2000/svg'
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                            >
                                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                    <g id='Artboard' transform='translate(-95.000000, -51.000000)'>
                                        <g id='logo' transform='translate(95.000000, 50.000000)'>
                                            <path
                                                id='Combined-Shape'
                                                fill={theme.palette.primary.main}
                                                d='M30,21.3918362 C30,21.7535219 29.9019196,22.1084381 29.7162004,22.4188007 C29.1490236,23.366632 27.9208668,23.6752135 26.9730355,23.1080366 L26.9730355,23.1080366 L23.714971,21.1584295 C23.1114106,20.7972624 22.7419355,20.1455972 22.7419355,19.4422291 L22.7419355,19.4422291 L22.741,12.7425689 L15,17.1774194 L7.258,12.7425689 L7.25806452,19.4422291 C7.25806452,20.1455972 6.88858935,20.7972624 6.28502902,21.1584295 L3.0269645,23.1080366 C2.07913318,23.6752135 0.850976404,23.366632 0.283799571,22.4188007 C0.0980803893,22.1084381 2.0190442e-15,21.7535219 0,21.3918362 L0,3.58469444 L0.00548573643,3.43543209 L0.00548573643,3.43543209 L0,3.5715689 C3.0881846e-16,2.4669994 0.8954305,1.5715689 2,1.5715689 C2.36889529,1.5715689 2.73060353,1.67359571 3.04512412,1.86636639 L15,9.19354839 L26.9548759,1.86636639 C27.2693965,1.67359571 27.6311047,1.5715689 28,1.5715689 C29.1045695,1.5715689 30,2.4669994 30,3.5715689 L30,3.5715689 Z'
                                            />
                                            <polygon
                                                id='Rectangle'
                                                opacity='0.077704'
                                                fill={theme.palette.common.black}
                                                points='0 8.58870968 7.25806452 12.7505183 7.25806452 16.8305646'
                                            />
                                            <polygon
                                                id='Rectangle'
                                                opacity='0.077704'
                                                fill={theme.palette.common.black}
                                                points='0 8.58870968 7.25806452 12.6445567 7.25806452 15.1370162'
                                            />
                                            <polygon
                                                id='Rectangle'
                                                opacity='0.077704'
                                                fill={theme.palette.common.black}
                                                points='22.7419355 8.58870968 30 12.7417372 30 16.9537453'
                                                transform='translate(26.370968, 12.771227) scale(-1, 1) translate(-26.370968, -12.771227) '
                                            />
                                            <polygon
                                                id='Rectangle'
                                                opacity='0.077704'
                                                fill={theme.palette.common.black}
                                                points='22.7419355 8.58870968 30 12.6409734 30 15.2601969'
                                                transform='translate(26.370968, 11.924453) scale(-1, 1) translate(-26.370968, -11.924453) '
                                            />
                                            <path
                                                id='Rectangle'
                                                fillOpacity='0.15'
                                                fill={theme.palette.common.white}
                                                d='M3.04512412,1.86636639 L15,9.19354839 L15,9.19354839 L15,17.1774194 L0,8.58649679 L0,3.5715689 C3.0881846e-16,2.4669994 0.8954305,1.5715689 2,1.5715689 C2.36889529,1.5715689 2.73060353,1.67359571 3.04512412,1.86636639 Z'
                                            />
                                            <path
                                                id='Rectangle'
                                                fillOpacity='0.35'
                                                fill={theme.palette.common.white}
                                                transform='translate(22.500000, 8.588710) scale(-1, 1) translate(-22.500000, -8.588710) '
                                                d='M18.0451241,1.86636639 L30,9.19354839 L30,9.19354839 L30,17.1774194 L15,8.58649679 L15,3.5715689 C15,2.4669994 15.8954305,1.5715689 17,1.5715689 C17.3688953,1.5715689 17.7306035,1.67359571 18.0451241,1.86636639 Z'
                                            />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <Typography
                                variant='h6'
                                sx={{
                                    ml: 3,
                                    lineHeight: 1,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    fontSize: '1.5rem !important'
                                }}
                            >
                                {themeConfig.templateName}
                            </Typography>
                        </Box>
                        <Box sx={{ mb: 6 }}>
                            <Typography variant='h5' sx={{ fontWeight: 600, mb: 1.5 }}>
                                Welcome to {themeConfig.templateName}! 👋🏻
                            </Typography>
                            <Typography variant='body2'>Please sign-in to your account and start the adventure</Typography>
                        </Box>
                        <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                                <Controller
                                    name='Username'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <TextField
                                            autoFocus
                                            label='Username'
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            error={Boolean(errors.Username)}
                                        />
                                    )}
                                />
                                {errors.Username && <FormHelperText sx={{ color: 'error.main' }}>{errors.Username.message}</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 4 }}>
                                <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.Password)}>
                                    Password
                                </InputLabel>
                                <Controller
                                    name='Password'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <OutlinedInput
                                            value={value}
                                            onBlur={onBlur}
                                            label='Password'
                                            onChange={onChange}
                                            id='auth-login-v2-password'
                                            autoComplete="new-password"
                                            error={Boolean(errors.Password)}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        edge='end'
                                                        onMouseDown={e => e.preventDefault()}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors.Password && (
                                    <FormHelperText sx={{ color: 'error.main' }} id=''>
                                        {errors.Password.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.Verification_code)}>
                                    Verification code
                                </InputLabel>
                                <Controller
                                    name='Verification_code'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <OutlinedInput
                                            label='Verification_code'
                                            value={value}
                                            id="verify-code"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            type='text'
                                            endAdornment={
                                                <Button
                                                    onClick={getVerifyCode}
                                                    sx={{width: 160}}
                                                    variant='contained'
                                                    disabled={!!countDown}
                                                >
                                                    {countDown ? countDown : 'Send'}
                                                </Button>
                                            }
                                        />
                                    )}
                                />
                                {errors.Verification_code && (
                                    <FormHelperText sx={{ color: 'error.main' }} id=''>
                                        {errors.Verification_code.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Box
                                sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
                            >
                                <FormControlLabel control={<Checkbox />} label='记住密码' />
                            </Box>
                            <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 7 }}>
                                登录
                            </Button>
                        </form>
                    </CardContent>
                </Card>
                <FooterIllustrations />
            </Box>
        </BlankLayout>
    )
}

export default Login;
